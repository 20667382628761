import React, { useState } from "react";
import Display from "../../components/TemplateEditorComponents/Display";
import { Notification } from "@two02/frontend-mui";
import {
  CardMedia,
  Typography,
  Select,
  MenuItem,
  Checkbox,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
} from "@mui/material";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Card from '@mui/material/Card';
import { useAuthState } from "react-firebase-hooks/auth";
import firebase from "@two02/frontend-firebase/dist/firebaseClient";

import ColorPicker from "../../components/TemplateEditorComponents/ColorPicker";
import BASE_URL from "../../assets/config";
import OptionsSelector from "../../components/TemplateEditorComponents/OptionsSelector";
import AudioOptions from "../../components/TemplateEditorComponents/AudioOptions";
import AuthWrapper from "../../components/AuthWrapper";

const prices = [[0.1, 0.4], [0.8, 0.3, 0.1], [0.2, 1], [0.3, 0.3, 1.0]];

const logoPositionOptions = [
  {
    text: "Top Left",
    value: "top-left",
  },
  {
    text: "Top Right",
    value: "top-right",
  },
  {
    text: "Bottom Left",
    value: "bottom-left",
  },
  {
    text: "Bottom Right",
    value: "bottom-right",
  },
];

const textPositionOptions = [
  {
    text: "Top",
    value: "top",
  },
  {
    text: "Middle",
    value: "middle",
  },
  {
    text: "Bottom",
    value: "bottom",
  },
];



const imageOptionsList = [
  {
    text: "Stable Defusion ( 30 Cents )",
    value: 1,
  },
  {
    text: "Pexels ( 10 Cents )",
    value: 2,
  },
];

const stockOptionsList = [
  {
    text: "AI Cartoon ( 1 Dollar )",
    value: 2,
  },
  {
    text: "Pexels stock videos ( 30 Cents )",
    value: 0,
  },
  {
    text: "Private stock videos ( 30 Cents )",
    value: 1,
  }
];

const videoOptionsList = [
  {
    text: "Normal Video ( 20 Cents )",
    value: 0,
  },
  {
    text: "AI Creator ( 3 Dollar )",
    value: 3,
  },
];

const EditTemplate = () => {
  const [logoPosition, setLogoPosition] = useState("top-left");
  const [textPosition, setTextPosition] = useState("middle");

  //Audio settings
  const [audio, setAudio] = useState(0);

  const [user,] = useAuthState(firebase.auth());
  // Template Name
  const [templateName, setTemplateName] = useState("");
  const [selectedVoice, setSelectedVoice] = useState(null);

  // subtitle settings
  const [subOption, setSubOption] = useState(true);
  const [fontSize, setFontSize] = useState(100);
  const [fontColor, setFontColor] = useState("#ffffff");
  const [strokeColor, setStrokeColor] = useState("#000000");
  const [strokeWidth, setStrokeWidth] = useState(1);
  const [paddingPercent, setPaddingPercent] = useState(5);
  const [highlightedColor, setHighlightedColor] = useState("#ffff00");
  const [highlighted, setHighlighted] = useState(true);

  // Logo Settings
  const [logoOptions, setLogoOptions] = useState(false);
  const [logo, setLogo] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [size, setSize] = useState(10);
  const [marginTop, setMarginTop] = useState(0);
  const [marginLeft, setMarginLeft] = useState(0);
  const [opacity, setOpacity] = useState(1);

  //Other
  const [imageOptions, setImageOptions] = useState(2);
  const [videoOptions, setVideoOptions] = useState(0);
  const [isFace, setIsFace] = useState(true);

  //Stock options
  const [stockOptions, setStockOptions] = useState(false);
  const [stock, setStock] = useState(0);

  //Notification
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [notiTrigger, setNotiTrigger] = useState(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    const bodyObj = {};
    const logoObj = {};
    const subtitleObj = {};
    const audioObj = {};
    try {
      if (templateName === "") {
        throw new Error("Please fill the template name");
      }
      bodyObj.name = templateName;
      if (logoOptions) {
        if (logo === "" || size === 0 || opacity === 0) {
          throw new Error("Please fill all the fields for logo options");
        }
        if (!isPng(logo)) {
          throw new Error("Please enter a valid URL for the logo");
        }
        logoObj.logo = logo;
        logoObj.position = logoPosition.split("-");
        logoObj.size = size;
        logoObj.margin_top = marginTop;
        logoObj.margin_left = marginLeft;
        logoObj.opacity = parseFloat(opacity);
        bodyObj.logo_options = logoObj;
      } else {
        bodyObj.logo_options = {};
      }

      if (subOption) {
        subtitleObj.font_size = fontSize;
        subtitleObj.font_color = fontColor;
        subtitleObj.stroke_color = strokeColor;
        subtitleObj.stroke_width = strokeWidth;
        subtitleObj.padding_percentage = paddingPercent;
        subtitleObj.text_position = textPosition;
        subtitleObj.highlighted_color = highlightedColor;
        subtitleObj.is_highlighted = highlighted;
        bodyObj.subtitle_options = subtitleObj;
      } else {
        //make is_off true
        bodyObj.subtitle_options = {
          is_off: true
        };

      }

      if (stockOptions) {
        let stock_obj = {
          stock_option: stock,
        };
        bodyObj.stock_options = stock_obj;
      } else {
        bodyObj.image_options = imageOptions;
        bodyObj.video_options = videoOptions;
        bodyObj.is_face = isFace;
      }

      audioObj.audio = audio;
      audioObj.voice_id = selectedVoice.voice_id;
      bodyObj.audio_options = audioObj;

      bodyObj.uid = user.uid;

      if (Object.keys(bodyObj).length > 0) {
        console.log(bodyObj);
        fetch(`${BASE_URL}/template/new/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bodyObj),
        })
          .then((response) => {
            return response.json().then(data => ({
              status: response.status,
              data
            }));
          })
          .then(({ status, data }) => {
            if (status === 200) {
              setMessage(`Template created successfully with id ${data._id}`); // Access the `id` from the parsed JSON object `data`
              setSeverity("success");
              setNotiTrigger(Date.now());
              window.location.href = "/template";
            } else {
              setMessage(`${data.detail}`);
              setSeverity("error");
              setNotiTrigger(Date.now());
              return;
            }
          })
          .catch((error) => {
            setMessage(`Error creating the template`);
            setSeverity("error");
            setNotiTrigger(Date.now());
          });
      } else {
        throw new Error("Please fill all the fields that are required");
      }
    } catch (error) {
      console.error("Error:", error);
      setMessage(error.message);
      setSeverity("error");
      setNotiTrigger(Date.now());
    }
  };

  const handleChange = (event) => {
    const inputUrl = event.target.value;
    setLogo(inputUrl);

    // Check if the input URL is empty or ends with ".png"
    if (!inputUrl || inputUrl.match(/\.(png)$/i)) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const isPng = (url) => {
    return url.match(/\.(png)$/) != null;
  };



  return (
    <AuthWrapper>
      <div>
        <div className="template-editor">
          <div className="options">
            <Grid container justifyContent="center">
              <TextField
                label="Template Name"
                type="text"
                value={templateName}
                required
                onChange={(e) => setTemplateName(e.target.value)}
                sx={{ marginTop: "1em" }}
              />
            </Grid>
            <h1>Advance Settings</h1>

            <h2>Audio Settings</h2>
            <div className="options-sublevel">
              <Box
                component="form"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5em",
                }}
              >
                <AudioOptions selectedVoice={selectedVoice} setSelectedVoice={setSelectedVoice} audio={audio} setAudio={setAudio} setMessage={setMessage} setSeverity={setSeverity} setNotiTrigger={setNotiTrigger} />

              </Box>
            </div>
            <Grid container justifyContent="center" spacing={1}>
              <Grid item>
                <Checkbox
                  checked={subOption}
                  color="secondary"
                  onChange={() => setSubOption((prev) => !prev)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </Grid>
              <Grid item>
                <Typography variant="h4">
                  <strong>Subtitle Settings</strong>
                </Typography>
              </Grid>
            </Grid>
            {subOption && (
              <div className="options-sublevel">
                <Box
                  component="form"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.5em",
                  }}
                >
                  <FormControlLabel
                    sx={{ marginRight: "auto" }}
                    labelPlacement="start"
                    control={
                      <Switch
                        checked={highlighted}
                        onChange={() => setHighlighted((prev) => !prev)}
                      />
                    }
                    label="Highlighted"
                  />
                  <TextField
                    label="Font Size"
                    type="number"
                    onChange={(e) => setFontSize(parseInt(e.target.value))}
                    value={fontSize}
                  />
                  <ColorPicker
                    label={"Font Color"}
                    color={fontColor}
                    onChange={setFontColor}
                  />
                  <ColorPicker
                    label={"Stroke Color"}
                    color={strokeColor}
                    onChange={setStrokeColor}
                  />
                  <TextField
                    label="Stroke-Width"
                    type="number"
                    onChange={(e) => setStrokeWidth(parseInt(e.target.value))}
                    value={strokeWidth}
                  />
                  <TextField
                    label="Padding Percentage"
                    type="number"
                    onChange={(e) =>
                      setPaddingPercent(parseInt(e.target.value))
                    }
                    value={paddingPercent}
                  />
                  <ColorPicker
                    label={"highlighted color"}
                    color={highlightedColor}
                    onChange={setHighlightedColor}
                  />
                </Box>
              </div>
            )}

            <Grid container justifyContent="center" spacing={1}>
              <Grid item>
                <Checkbox
                  checked={logoOptions}
                  color="secondary"
                  onChange={() => setLogoOptions((prev) => !prev)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </Grid>
              <Grid item>
                <Typography variant="h4">
                  <strong>Logo Settings</strong>
                </Typography>
              </Grid>
            </Grid>
            {logoOptions && (
              <div className="options-sublevel">
                <Box
                  component="form"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.5em",
                  }}
                >
                  <div>
                    <TextField
                      label="Logo URL"
                      variant="outlined"
                      fullWidth
                      value={logo}
                      onChange={handleChange}
                      error={!isValid} // Set error state based on validation
                      helperText={
                        !isValid
                          ? "URL must be a PNG image"
                          : "Please enter a URL of a PNG image"
                      }
                    />
                    {isPng(logo) && (
                      <Card>
                        <CardMedia
                          component="img"
                          image={logo}
                          alt="Logo Image"
                          height={size}
                          sx={{ objectFit: "contain" }}
                        />
                      </Card>
                    )}
                  </div>
                  <TextField
                    label="Size"
                    type="number"
                    onChange={(e) => setSize(parseInt(e.target.value))}
                    value={size}
                  />
                  <TextField
                    label="Margin Top"
                    type="number"
                    onChange={(e) => setMarginTop(parseInt(e.target.value))}
                    value={marginTop}
                  />
                  <TextField
                    label="Margin Left"
                    type="number"
                    onChange={(e) => setMarginLeft(parseInt(e.target.value))}
                    value={marginLeft}
                  />
                  <TextField
                    label="Opacity"
                    type="number"
                    onChange={(e) => setOpacity(parseInt(e.target.value))}
                    value={opacity}
                  />
                </Box>
              </div>
            )}

            <h2>Other Options</h2>
            <Grid container justifyContent="center" spacing={1}>
              <Grid item>
                <Checkbox
                  checked={stockOptions}
                  color="secondary"
                  onChange={() => setStockOptions((prev) => !prev)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </Grid>
              <Grid item>
                <Typography variant="h4">
                  <strong>Stock Options</strong>
                </Typography>
              </Grid>
            </Grid>
            {stockOptions ? (
              <div className="options-sublevel">
                <Box
                  component="form"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.5em",
                  }}
                >
                  <FormControl fullWidth>
                    <InputLabel>Stock Options</InputLabel>
                    <Select
                      value={stock}
                      label="Stock Options"
                      onChange={(e) => setStock(e.target.value)}
                    >
                      {stockOptionsList.map((option, index) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.text}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </div>
            ) : (
              <div className="options-sublevel">
                <Box
                  component="form"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.5em",
                  }}
                >
                  <OptionsSelector
                    state={imageOptions}
                    setState={setImageOptions}
                    optionList={imageOptionsList}
                    label="Image Options"
                  />

                  <OptionsSelector
                    state={videoOptions}
                    setState={setVideoOptions}
                    optionList={videoOptionsList}
                    label="Video Options"
                  />

                  <FormControlLabel
                    sx={{ marginRight: "auto" }}
                    labelPlacement="start"
                    control={
                      <Switch
                        checked={isFace}
                        onChange={() => setIsFace((prev) => !prev)}
                      />
                    }
                    label="Skip Human Faces"
                  />
                </Box>
              </div>
            )}
          </div>

          <div className="canvas">
            <Display
              logoPosition={logoPosition}
              textPosition={textPosition}
              highlightedColor={highlightedColor}
            />
          </div>

          <div className="buttons">
            <Typography variant="h6">
              <strong>Select Logo Position</strong>
            </Typography>

            <OptionsSelector
              state={logoPosition}
              setState={setLogoPosition}
              optionList={logoPositionOptions}
              label="Logo Position"
            />

            <Typography variant="h6">
              <strong>Select subtitle Position</strong>
            </Typography>

            <OptionsSelector
              state={textPosition}
              setState={setTextPosition}
              optionList={textPositionOptions}
              label="Text Position"
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <p style={{ textAlign: "center" }}>
            Est. Cost: $
            {(
              prices[0][audio] +
              (stockOptions ? prices[3][stock] : (prices[2][videoOptions] + prices[1][imageOptions])) +
              0.1
            ).toFixed(2)}
          </p>

          <Button
            sx={{ margin: "auto auto 1em" }}
            onClick={handleSubmit}
            variant="contained"
          >
            Add Template
          </Button>
        </div>
        <Notification msg={message} type={severity} trigger={notiTrigger} />
      </div>
    </AuthWrapper>
  );
};

export default EditTemplate;
