import React, { useEffect, useState } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import firebase from "@two02/frontend-firebase/dist/firebaseClient";
import Payment from '../components/Payment';
import { Token } from '@two02/frontend-general';
import { FundsHandler, FreeFundsHandler, fetchTokens, deleteToken, createToken } from '@two02/frontend-firebase';
import AuthWrapper from '../components/AuthWrapper';
import { uuidv4 } from "@firebase/util";


export default function Dashboard() {
    const [user,] = useAuthState(firebase.auth());

    const [plan, setPlan] = useState(undefined);
    const [planColor, setPlanColor] = useState("blue");
    const [amount, setAmount] = useState('');
    const [freeamount, setFreeAmount] = useState('');
    const [buttonsDisabled, setButtonsDisabled] = useState(true);

    const fetchFunds = async () => {
        const uid = user.uid;
        if (uid) {
            //add interval to update funds every 5 seconds
            const funds = await FundsHandler(uid);
            const free_funds = await FreeFundsHandler(uid)
            const funds_text = funds[0].data.credits.toFixed(3);
            const free_funds_text = free_funds[0].data.credits.toFixed(3);
            setFreeAmount(`${free_funds_text} USD`);
            setAmount(`${funds_text} USD`);
        }
    };

    const getPlan = async () => {
        try {
            const userDoc = await firebase.firestore().collection("users").doc(user.uid).get();
            const currentPlan = userDoc.data()?.plan ?? null;
            setPlan(currentPlan);

            if (!currentPlan) {
                setButtonsDisabled(false);
            } else {
                setButtonsDisabled(true);
            }

            switch (currentPlan) {
                case "Basic":
                    setPlanColor("bg-blue-200 text-blue-600");
                    break;

                case "Standard":
                    setPlanColor("bg-green-200 text-green-600");
                    break;

                case "Pro":
                    setPlanColor("bg-yellow-200 text-yellow-600");
                    break;

                default:
                    setPlanColor("blue");
            }

        } catch (error) {
            console.error("Error fetching user plan:", error);
            setPlan(undefined);
        }
    };

    useEffect(() => {
        if (!user) return;
        getPlan();
        fetchFunds();
        // eslint-disable-next-line
    }, [user]);

    return (
        <AuthWrapper>
            <div className="user-logged-in">

                <div className="m-10">
                    <div className="flex flex-wrap -mx-4">

                        <div className="w-full md:w-1/2 px-4 mb-4 md:mb-0">
                            <div className="rounded-lg bg-zinc-700 overflow-hidden shadow-lg p-5">
                                <div className="flex justify-between items-center">
                                    <div>
                                        <h3 className="text-zinc-100 font-semibold text-lg">{plan ? "MONTHLY CREDITS" : "PAID CREDITS"}</h3>
                                        <p className="text-zinc-100">{amount}</p>
                                        {plan &&
                                            <p className="inline-block mr-1 last:mr-0 py-1 px-2 rounded bg-indigo-200 text-xs font-semibold text-indigo-600 uppercase">Life Time User</p>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-full md:w-1/2 px-4 mb-4 md:mb-0">
                            <div className="rounded-lg bg-zinc-700 overflow-hidden shadow-lg p-5">
                                <div className="flex justify-between items-center">
                                    <div>
                                        <h3 className="text-zinc-100 font-semibold text-lg">FREE CREDITS</h3>
                                        <p className="text-zinc-100">{freeamount}</p>
                                        {plan &&
                                            <p className={`inline-block mr-1 last:mr-0 py-1 px-2 rounded ${planColor} text-xs font-semibold uppercase`}>{plan}</p>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <Payment fetchFunds={fetchFunds} disabled={buttonsDisabled} />
                <div className='m-10 text-center'>
                    <Token
                        uid={user?.uid ?? ''}
                        fetchTokens={fetchTokens}
                        deleteToken={deleteToken}
                        createToken={createToken}
                        uuidv4={uuidv4}
                    />
                </div>
            </div>
        </AuthWrapper>
    );
}