import React, { useEffect } from 'react';
import '../../Styles/HomeAnimation.scss';
import { Slider } from '../../assets/HomePageAssets/HomeAnimationScript';
import vid1 from '../../assets/HomePageAssets/new/8.mp4';
import vid2 from '../../assets/HomePageAssets/new/9.mp4';
import vid3 from '../../assets/HomePageAssets/new/10.mp4';
import FAQ from './FAQ';


const HomeAnimation = () => {

    useEffect(() => {
        new Slider();
    }, []);

    return (
        <div className="fixed inset-0">
            <figure className="logo">
                <img alt="" src={"https://s3-us-west-2.amazonaws.com/s.cdpn.io/58281/logo_copy_copy.svg"} />
            </figure>

            <div className="slider js-slider">
                <div className="slider__inner js-slider__inner"></div>

                <div className="slide js-slide">
                    <div className="slide__content">
                        <figure className="slide__img js-slide__img">
                            <video
                                loop
                                autoPlay
                                muted
                                playsInline
                                alt=""
                                src={vid1}
                                className="video-class"
                            />
                        </figure>
                        <figure className="slide__img js-slide__img">
                            <video
                                loop
                                autoPlay
                                muted
                                playsInline
                                alt=""
                                src={vid2}
                                className="video-class"
                            />
                        </figure>
                    </div>

                    <div className="slider__text js-slider__text">
                        <div className="slider__text-line js-slider__text-line"><div>Black is</div></div>
                        <div className="slider__text-line js-slider__text-line"><div>timeless. Black is</div></div>
                        <div className="slider__text-line js-slider__text-line"><div>the colour of</div></div>
                        <div className="slider__text-line js-slider__text-line"><div>Eternity.</div></div>
                    </div>

                </div>

                <div className="slide js-slide">
                    <div className="slide__content">
                        <figure className="slide__img js-slide__img">
                            <video
                                loop
                                autoPlay
                                muted
                                playsInline
                                alt=""
                                src={vid3}
                                className="video-class"
                            />
                        </figure>
                        <figure className="slide__img js-slide__img">
                            <video
                                loop
                                autoPlay
                                muted
                                playsInline
                                alt=""
                                src={vid1}
                                className="video-class"
                            />
                        </figure>
                    </div>
                </div>

                <div className="slide js-slide">
                    <div className="slide__content">
                        <FAQ />
                    </div>

                </div>

                <nav className="slider__nav js-slider__nav">
                    <div className="slider-bullet js-slider-bullet">
                        <span className="slider-bullet__text js-slider-bullet__text">01</span>
                        <span className="slider-bullet__line js-slider-bullet__line"></span>
                    </div>
                    <div className="slider-bullet js-slider-bullet">
                        <span className="slider-bullet__text js-slider-bullet__text">02</span>
                        <span className="slider-bullet__line js-slider-bullet__line"></span>
                    </div>
                    <div className="slider-bullet js-slider-bullet">
                        <span className="slider-bullet__text js-slider-bullet__text">03</span>
                        <span className="slider-bullet__line js-slider-bullet__line"></span>
                    </div>
                </nav>

                <div className="scroll js-scroll">Scroll</div>

            </div>

            <div className="vert-text">
                <span>
                    Wings+Horns<br />
                    X Kyoto Black
                </span>
            </div>
        </div>
    );
};

export default HomeAnimation;