import React, { useEffect, useRef, useState } from "react";
import Button from '@mui/material/Button';

const roadmapItems = [
    {
        header: "Official Release of Vimzy.art",
        date: "April 5, 2024",
        content: " Launch the primary version of Vimzy.art, the AI video generator API, to the public. This release includes the core functionalities and user interface designed for seamless video creation and integration with various platforms."
    },
    {
        header: "AI Cartoon Feature with Stock Footage",
        date: "May 1, 2024",
        content: "Introduce a new feature that allows users to create AI-generated cartoon videos. This update will also include a stock footage library, enabling users to enhance their videos with high-quality, pre-existing clips."
    },
    {
        header: "Integration with SORA",
        date: "Future",
        content: "Plan and execute the integration of Vimzy.art with SORA, a powerful platform for advanced video editing and management. This integration aims to expand the capabilities of Vimzy.art, providing users with more robust editing tools and seamless workflow."
    },
    {
        header: "Team Planning Feature",
        date: "Future",
        content: "Develop a team planning feature that allows multiple users to collaborate on video projects within Vimzy.art. This feature will include shared workspaces, task assignments, and real-time collaboration tools to enhance team productivity and project management."
    },
    {
        header: "Own Hosted Video Maker",
        date: "Future",
        content: "Create a fully hosted video maker solution within Vimzy.art. This feature will enable users to produce, edit, and host videos directly on the platform, offering a comprehensive end-to-end video creation and distribution solution."
    }
];

const Roadmap = () => {
    return (
        <div>
            <h1 className="mb-12 pt-6 text-center">A Brief History of Our Journey</h1>
            <div className="m-auto pl-6 pr-6 w-full max-w-xl">
                {roadmapItems.map((item, index) => (
                    <TimelineItem item={item} isLastItem={index + 1 === roadmapItems.length} key={item.header} />
                ))}
            </div>
        </div>
    );
};


const TimelineItem = ({ item, isLastItem }) => {
    const [open, setOpen] = useState(false);
    const [height, setHeight] = useState(0);
    const contentRef = useRef(null);

    useEffect(() => {
        if (!contentRef.current || !open) return;
        setHeight(contentRef?.current?.clientHeight);
    }, [open]);

    return (
        <div className="timeline__item relative pb-8 ">
            {!isLastItem &&
                <div className="timeline__item-line absolute bg-current top-4 left-10 w-1 h-full transform-translate-x-1/2"></div>
            }
            <div className="flex">
                <Button style={{ margin: "-1em 0 0 -2em", height: "fit-content" }} className="timeline__arrow" type="button" aria-labelledby="item1-name" aria-expanded="false" aria-controls="item1-ctrld" aria-haspopup="true" data-item="1" onClick={() => setOpen((prev) => !prev)}>
                    <svg className={`timeline__arrow-icon transform ${!open ? "-rotate-90" : ""} transition-transform duration-300 ease-in-out block pointer-events-none w-full h-auto`} viewBox="0 0 24 24" width="24px" height="24px">
                        <polyline points="7 10,12 15,17 10" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                    </svg>
                </Button>
                <span className="timeline__dot bg-current rounded-full mr-4 inline-block flex-shrink-0 mt-2 mb-2 relative w-3 h-3"></span>
                <span className="w-full">
                    <time className="text-sm leading-10" dateTime="1970-01-01">{item.date}</time>
                    <br />
                    <strong className="text-2xl leading-5">{item.header}</strong>
                </span>
            </div>
            <div style={{ height: `${open ? `${height}px` : "0"}` }} className={`timeline__item-body ml-16 rounded-md overflow-hidden mt-2 transition-height duration-300`} role="region">
                <div className={`timeline__item-body-content transition-opacity transition-visibility duration-300 ${open ? "opacity-100 visible delay-300" : "opacity-0 pt-2 pb-2 pr-3 pl-3 invisible"}`}>
                    <p ref={contentRef} className="timeline__item-p">{item.content}</p>
                </div>
            </div>
        </div>
    );
};

export default Roadmap;


