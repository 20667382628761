import React, { useEffect, useRef, useState } from "react";

const faqItems = [
    {
        header: "Official Release of Vimzy.art",
        content: " Launch the primary version of Vimzy.art, the AI video generator API, to the public. This release includes the core functionalities and user interface designed for seamless video creation and integration with various platforms."
    },
    {
        header: "AI Cartoon Feature with Stock Footage",
        content: "Introduce a new feature that allows users to create AI-generated cartoon videos. This update will also include a stock footage library, enabling users to enhance their videos with high-quality, pre-existing clips."
    },
    {
        header: "Integration with SORA",
        content: "Plan and execute the integration of Vimzy.art with SORA, a powerful platform for advanced video editing and management. This integration aims to expand the capabilities of Vimzy.art, providing users with more robust editing tools and seamless workflow."
    },
    {
        header: "Team Planning Feature",
        content: "Develop a team planning feature that allows multiple users to collaborate on video projects within Vimzy.art. This feature will include shared workspaces, task assignments, and real-time collaboration tools to enhance team productivity and project management."
    },
    {
        header: "Own Hosted Video Maker",
        content: "Create a fully hosted video maker solution within Vimzy.art. This feature will enable users to produce, edit, and host videos directly on the platform, offering a comprehensive end-to-end video creation and distribution solution."
    }
];

const FAQ = () => {
    return (
        <div className="bg-[#66666666] w-full h-full p-20 grid grid-cols-2">
            <div>
                <h6>FAQ</h6>
                <div className="mt-5">
                    <p>Have more questions? </p>
                    <p>Contact us!</p>
                </div>

            </div>
            <div className="m-auto mt-0 pl-6 pr-6 max-w-xl h-full overflow-scroll no-scrollbar">
                {faqItems.map((item) => (
                    <TimelineItem item={item} key={item.header} />
                ))}
            </div>
        </div>
    );
};


const TimelineItem = ({ item }) => {
    const [open, setOpen] = useState(false);
    const [height, setHeight] = useState(0);
    const contentRef = useRef(null);

    useEffect(() => {
        if (!contentRef.current || !open) return;
        setHeight(contentRef?.current?.clientHeight);
    }, [open]);

    return (
        <div className="timeline__item relative pb-8 ">
            <label className="flex cursor-pointer">
                <input className="hidden" type="button" onClick={() => setOpen((prev) => !prev)} />
                <span className="timeline__dot mr-4 inline-block flex-shrink-0 mt-2 mb-2 relative w-3 h-3">
                    <svg className={`timeline__arrow-icon transform ${!open ? "-rotate-90" : ""} transition-transform duration-300 ease-in-out block pointer-events-none w-full h-auto`} viewBox="0 0 24 24" width="24px" height="24px">
                        <polyline points="7 10,12 15,17 10" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                    </svg>
                </span>
                <span className="w-full">
                    <strong className="text-2xl leading-5">{item.header}</strong>
                </span>
            </label>
            <div style={{ height: `${open ? `${height}px` : "0"}` }} className={`timeline__item-body ml-16 rounded-md overflow-hidden mt-2 transition-height duration-300`} role="region">
                <div className={`timeline__item-body-content transition-opacity transition-visibility duration-300 ${open ? "opacity-100 visible delay-300" : "opacity-0 pt-2 pb-2 pr-3 pl-3 invisible"}`}>
                    <p ref={contentRef} className="timeline__item-p">{item.content}</p>
                </div>
            </div>
        </div>
    );
};

export default FAQ

