import React from "react";
import HistoryBgImg from "../assets/Images/RresponsiveImages/HistoryBgImg";
import { PaymentHistory } from "@two02/frontend-general";
import { updatePaymentHistory } from "@two02/frontend-firebase";

const PaymentHistoryPage = () => {
    return (
        <PaymentHistory
            BgBlurred={true}
            BgImgComponent={HistoryBgImg}
            updateHistory={updatePaymentHistory}
        />
    );
};

export default PaymentHistoryPage;