import React, { useState, useEffect } from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Grid,
  TextField,
  Button,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import FaceRetouchingOffIcon from "@mui/icons-material/FaceRetouchingOff";
import Pagination from "@mui/material/Pagination";
import BASE_URL from "../../assets/config";
import { useAuthState } from "react-firebase-hooks/auth";
import firebase from "@two02/frontend-firebase/dist/firebaseClient";
import { FloatingDialogBox, Notification, Spinner } from "@two02/frontend-mui";
import AuthWrapper from "../../components/AuthWrapper";

const Templates = () => {
  const [user,] = useAuthState(firebase.auth());
  const [videos, setVideos] = useState([]);
  const [filteredVideos, setFilteredVideos] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [videosPerPage] = useState(6);
  const [loading, setLoading] = useState(false);

  // notifications
  const [notiMsg, setNotiMsg] = useState("");
  const [notiType, setNotiType] = useState("info");
  const [notiTrigger, setNotiTrigger] = useState(null);

  const fetchVideoIds = async () => {
    if (!user) return;
    setLoading(true);
    const response = await fetch(`${BASE_URL}/template/get/uid/${user.uid}`);
    const ids = await response.json();

    const videoDetails = await Promise.all(
      ids.map(async (id) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };

        const videoResponse = await fetch(`${BASE_URL}/template/get/${id._id}`, requestOptions);
        const videoData = await videoResponse.json();
        console.log(videoData);
        return videoData;
      })
    );

    setVideos(videoDetails);
    setLoading(false);
  };

  useEffect(() => {
    fetchVideoIds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    const sortedVideos = videos.sort((a, b) =>
      a.status === "failed" ? 1 : b.status === "failed" ? -1 : 0
    );

    const searchedVideos = sortedVideos.filter(
      (video) =>
        video._id &&
        typeof video._id === "string" &&
        video._id.toLowerCase().includes(searchQuery.toLowerCase())
    );

    setFilteredVideos(searchedVideos);
  }, [videos, searchQuery]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset to the first page on new search
  };

  // Pagination
  const indexOfLastVideo = currentPage * videosPerPage;
  const indexOfFirstVideo = indexOfLastVideo - videosPerPage;
  const currentVideos = filteredVideos.slice(
    indexOfFirstVideo,
    indexOfLastVideo
  );

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  function isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return true;
    }
    return false;
  }

  const handleImageoption = (image) => {
    try {
      if (isEmpty(image.stock_options)) {
        return;
      }
      if (image.image_options === 0) {
        return (
          <div>
            <Typography color="text.secondary">Image : Midjourney</Typography>
          </div>
        );
      } else if (image.image_options === 1) {
        return (
          <div>
            <Typography color="text.secondary">Image : Stable Diffusion</Typography>
          </div>
        );
      } else if (image.image_options === 2) {
        return (
          <div>
            <Typography color="text.secondary">Image : Pexels</Typography>
          </div>
        );
      } else {
        return;
      }
    } catch (Exception) {
      return;
    }
  };

  const handleCost = (video) => {
    try {
      let product_cost = 0.00;
      const stock = video.stock_options.stock_option;
      if (isEmpty(video.stock_options.stock_option)) {
        // Video cost
        if (video.video_options === 0) {
          product_cost += 0.20;
        } else {
          product_cost += 1.00;
        }

        // Image cost
        if (video.image_options === 0) {
          product_cost += 0.80;
        } else if (video.image_options === 1) {
          product_cost += 0.30;
        } else if (video.image_options === 2) {
          product_cost += 0.10;
        }
      } else {
        // Stock cost
        if (stock === 0) {
          product_cost += 0.30;
        } else if (stock === 1) {
          product_cost += 0.30;
        } else if (stock === 2) {
          product_cost += 1.00;
        }
      }
      // Audio cost
      if (video.audio_options.audio === 0) {
        product_cost += 0.10;
      } else {
        product_cost += 0.40;
      }

      // Fixed cost
      product_cost += 0.10;

      return (
        <Typography color="text.primary">Cost : {product_cost.toFixed(2)} USD</Typography>
      );
    } catch (Exception) {
      return;
    }
  };

  const handleVideooption = (video) => {
    try {
      if (isEmpty(video.stock_options)) {
        return;
      }
      if (video.video_options === 0) {
        return (
          <Typography color="text.secondary">Video : Normal</Typography>
        );
      } else if (video.video_options === 1) {
        return <Typography color="text.secondary">Video : AI Creator</Typography>;
      } else {
        return;
      }
    } catch (Exception) {
      return;
    }
  };

  const handleStockoption = (video) => {
    try {
      const stock = video.stock_options.stock_option;
      if (stock === 0) {
        return (
          <Typography color="text.secondary">Stock Video : Pexels</Typography>
        );
      } else if (stock === 1) {
        return (
          <Typography color="text.secondary">Stock Video : Private</Typography>
        );
      } else if (stock === 2) {
        return (
          <Typography color="text.secondary">Stock Video : AI Cartoon</Typography>
        );
      } else {
        return;
      }
    } catch (Exception) {
      return;
    }
  };

  const handleLogooption = (logo) => {
    try {
      const url = logo.logo_options.logo;
      if (url !== null) {
        return (
          <div>
            <Grid container>
              <Grid item>
                <Typography color="text.secondary">Logo :</Typography>
              </Grid>
              <Grid item>
                <CardMedia
                  component="img"
                  height="50px"
                  width="auto"
                  image={url}
                  alt="logo url"
                  sx={{ objectFit: "contain" }}
                  style={{ width: "50px", height: "auto" }}
                />
              </Grid>
            </Grid>
          </div>
        );
      } else {
        return;
      }
    } catch (Exception) {
      return;
    }
  };

  const handleAudiooption = (audio) => {
    try {
      if (audio.audio_options.audio === 0) {
        return (
          <div>
            <Typography color="text.secondary">Audio : Openai</Typography>
            <Typography color="text.secondary">
              Voice : {audio.audio_options.voice_id}
            </Typography>
          </div>
        );
      } else if (audio.audio_options.audio === 2) {
        return (
          <div>
            <Typography color="text.secondary">Audio : ElevenLabs</Typography>
            <Typography color="text.secondary">
              Voice : {audio.audio_options.voice_id}
            </Typography>
          </div>
        );
      } else {
        return;
      }
    } catch (Exception) {
      return;
    }
  };

  const handleFaceoption = (face) => {
    try {
      if (face.is_face === true) {
        return (
          <Typography color="text.secondary">
            Humen Face Remove : {" "}
            <FaceRetouchingOffIcon
              style={{ color: "green", fontSize: "20px" }}
            />
          </Typography>
        );
      } else {
        return;
      }
    } catch (Exception) {
      return;
    }
  };

  const handleDelete = (id) => {
    // Replace 'your-api-url' with the actual URL where you want to send the data
    fetch(`${BASE_URL}/template/delete/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status === 200) {
          setNotiMsg("Template deleted successfully");
          setNotiTrigger(Date.now());
          setNotiType("success");
          fetchVideoIds();
        } else {
          setNotiMsg("Error deleting Template");
          setNotiTrigger(Date.now());
          setNotiType("error");
        }
      })
      .catch((error) => {
        console.error("Error deleting Template:", error);
      });
  };

  const totalPages = Math.ceil(filteredVideos.length / videosPerPage);

  return (
    <AuthWrapper>
      <div>
        <Grid sx={{ m: 0 }} container justifyContent="center" direction={"column"} spacing={3}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction={"column"}
            spacing={3}
          >
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                href="/template/new"
                style={{ marginTop: "30px" }}
              >
                New Template
              </Button>
            </Grid>
          </Grid>
          <Grid item sx={{ padding: "0 2em" }}>
            <TextField
              fullWidth
              label="Search by Template ID"
              variant="outlined"
              value={searchQuery}
              onChange={handleSearchChange}
              style={{ marginBottom: "20px" }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          {loading && <Spinner />}
          {currentVideos.map((video) => (
            <TemplateCards video={video} handleDelete={handleDelete} key={video._id}
              handleAudiooption={handleAudiooption}
              handleFaceoption={handleFaceoption}
              handleImageoption={handleImageoption}
              handleLogooption={handleLogooption}
              handleVideooption={handleVideooption}
              handleCost={handleCost}
              handleStockoption={handleStockoption}
            />
          ))}
        </Grid>
        {totalPages > 1 && (
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "center",
            }}
          />
        )}
      </div>
      <Notification msg={notiMsg} type={notiType} trigger={notiTrigger} />
    </AuthWrapper>
  );
};

export default Templates;

const TemplateCards = ({
  video,
  handleDelete,
  handleAudiooption,
  handleFaceoption,
  handleImageoption,
  handleVideooption,
  handleLogooption,
  handleCost,
  handleStockoption,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  return (
    <Grid item xs={12} sm={6} md={4} key={video._id}>
      <Card>
        <CardContent>
          <Typography variant="h5" component="div">
            Template Name: {video.name}
          </Typography>
          <Typography component="div">Template ID: {video._id}</Typography>
          {handleAudiooption(video)}
          {handleFaceoption(video)}
          {handleStockoption(video)}
          {handleImageoption(video)}
          {handleVideooption(video)}
          {handleLogooption(video)}
          {handleCost(video)}
          <Grid container justifyContent="center" spacing={3}>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setDialogOpen(true)}
                target="_blank"
                startIcon={<DeleteIcon />}
                style={{ marginTop: "20px" }}
              >
                Delete
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <FloatingDialogBox
        onSubmit={() => handleDelete(video._id)}
        handleCloseDialog={handleCloseDialog}
        open={dialogOpen}
        text={{
          header: "Delete Template",
          paragraph: `Do you want to delete the template:\n${video._id}`,
          submit: "Yes",
        }}
      />
    </Grid>
  );
};