import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Button,
} from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import BASE_URL from "../../assets/config";
import { useAuthState } from "react-firebase-hooks/auth";
import firebase from "@two02/frontend-firebase/dist/firebaseClient";
import { Notification } from "@two02/frontend-mui";
import { Spinner } from "@two02/frontend-mui";
import TaskCard from "./Taskcard";
import NewTask from "./NewTask";
import AuthWrapper from "../../components/AuthWrapper";

const Tasks = () => {
  const [user, ] = useAuthState(firebase.auth());
  const [videos, setVideos] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  // notifications
  const [notiMsg, setNotiMsg] = useState("");
  const [notiType, setNotiType] = useState("info");
  const [notiTrigger, setNotiTrigger] = useState(null);
  const [openNewTask, setOpenNewTask] = useState(false);

  const fetchVideoIds = async (reset = false) => {
    if (!user) return;
    const response = await fetch(`${BASE_URL}/video/get/${user.uid}?page=${page}`);
    const ids = await response.json();

    if (ids.length === 0) {
      setHasMore(false);
      return;
    }

    const videoDetails = await Promise.all(
      ids.map(async (id) => {
        const response = await fetch(`${BASE_URL}/video/status/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ id: id.id }),
        });
        const videoData = await response.json();
        if (id.text !== undefined) {
          videoData.text = id.text;
        }
        if (id.jobAt !== undefined) {
          videoData.jobAt = id.jobAt;
        }
        return videoData;
      })
    );

    if (reset) {
      setVideos(videoDetails);
    } else {
      setVideos((prevVideos) => [...prevVideos, ...videoDetails]);
    }

    setPage((prevPage) => prevPage + 1);
  };

  useEffect(() => {
    if (user) {
      fetchVideoIds(true);
    }
    // eslint-disable-next-line
  }, [user]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setPage(1);
    fetchVideoIds(true);
  };

  const handleDelete = (id) => {
    fetch(`${BASE_URL}/video/delete/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status === 200) {
          setNotiMsg("Video deleted successfully");
          setNotiTrigger(Date.now());
          setNotiType("success");
          fetchVideoIds(true);
        } else {
          setNotiMsg("Error deleting video");
          setNotiTrigger(Date.now());
          setNotiType("error");
        }
      })
      .catch((error) => {
        console.error("Error deleting video:", error);
      });
  };

  return (
    <AuthWrapper>
      <div>
        <NewTask open={openNewTask} setOpen={setOpenNewTask} user={user} />
        <Grid container justifyContent="center" direction="column" spacing={3}>
          <Grid container justifyContent="center" alignItems="center" direction="column" spacing={3}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                style={{ marginTop: "30px" }}
                onClick={() => { setOpenNewTask(true) }}
              >
                New Video Task
              </Button>
            </Grid>
          </Grid>
          <Grid item sx={{ padding: "0 2em" }}>
            <TextField
              fullWidth
              label="Search by Video ID"
              variant="outlined"
              value={searchQuery}
              onChange={handleSearchChange}
              style={{ marginBottom: "20px" }}
            />
          </Grid>
        </Grid>
        <InfiniteScroll
          dataLength={videos.length}
          next={fetchVideoIds}
          hasMore={hasMore}
          loader={<Spinner />}
          endMessage={<p style={{ textAlign: "center" }}>No more videos to display.</p>}
        >
          <Grid container spacing={3}>
            {videos.map((video) => (
              <TaskCard handleDelete={(id) => handleDelete(id)} video={video} key={video.id} />
            ))}
          </Grid>
        </InfiniteScroll>
      </div>
      <Notification msg={notiMsg} trigger={notiTrigger} type={notiType} />
    </AuthWrapper>
  );
};

export default Tasks;

