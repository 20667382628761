import React, { useState, useEffect } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import firebase from "@two02/frontend-firebase/dist/firebaseClient";

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import {ThemeToggleButton} from '@two02/frontend-mui';
import { Divider, Drawer, ListItem } from '@mui/material';
import List from '@mui/material/List';

const settings = [['Dashboard', '/dashboard'], ['Token Usage', '/token-usage'], ['Payment History', '/history']];

const NavBar = ({ setIsDark, isDark }) => {
    return (
        <div id="NavBar">
            <ResponsiveAppBar setIsDark={setIsDark} isDark={isDark} />
        </div>
    );
};

export default NavBar;

const ResponsiveAppBar = ({ setIsDark, isDark }) => {
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);
    const [user,] = useAuthState(firebase.auth());
    const [pages, setPages] = useState([
        ["Dashboard", "/dashboard"],
        ["Pricing", "/pricing"]
    ]);
    const [url, setUrl] = useState(null);

    useEffect(() => {
        if (user?.photoURL) {
            setUrl(user.photoURL);
        } else {
            setUrl(null);
        }

        if (user) {
            setPages([
                ["Dashboard", "/dashboard"],
                ["Tasks", "/tasks"],
                ["Template", "/template"],
                ["Pricing", "/pricing"]
            ]);
        } else {
            setPages([
                ["Dashboard", "/dashboard"],
                ["Pricing", "/pricing"]
            ]);
        }
    }, [user]);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = (link) => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <AppBar position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        VIMZY
                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                    </Box>
                    <Drawer
                        open={Boolean(anchorElNav)}
                        onClose={handleCloseNavMenu}
                        sx={{ display: { xs: 'block', md: 'none' } }}
                    >
                        <Box sx={{ width: 250, display: 'flex', justifyContent: 'center', flexDirection: "column", height: "100%" }} role="presentation">
                            <List>
                                <Divider />

                                {pages.map((page) => (
                                    <div key={page[0]}>
                                        <ListItem
                                            sx={{ padding: "0.5em 0 0.5em 1em" }}
                                            component="a"
                                            href={page[1]}
                                            style={{ textDecoration: "unset", color: "unset" }}
                                        >
                                            <Typography
                                                textAlign="center"
                                                sx={{ fontSize: "1.5em" }}
                                            >
                                                {page[0]}
                                            </Typography>
                                        </ListItem>
                                        <Divider />
                                    </div>
                                ))}

                                <ListItem
                                    sx={{ padding: "0.5em 0 0.5em 1em" }}
                                    component="a"
                                    href="https://docs.vimzy.art/"
                                    style={{ textDecoration: "unset", color: "unset" }}
                                    target='_blank'
                                >
                                    <Typography
                                        textAlign="center"
                                        sx={{ fontSize: "1.5em" }}
                                    >
                                        API
                                    </Typography>
                                </ListItem>
                                <Divider />
                            </List>
                        </Box>

                    </Drawer>
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        VIMZY
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {pages.map((page) => (
                            <Typography
                                key={page[0]}
                                sx={{ my: 2, mx: 2, display: 'block', textDecoration: "unset" }}
                                href={page[1]}
                                component="a"
                            >
                                {page[0]}
                            </Typography>
                        ))}

                        <Typography
                            sx={{ my: 2, mx: 2, display: 'block', textDecoration: "unset" }}
                            href="https://docs.vimzy.art/"
                            component="a"
                            target='_blank'
                        >
                            API
                        </Typography>

                    </Box>

                    <Box>
                        <ThemeToggleButton setIsDark={setIsDark} isDark={isDark} />
                    </Box>

                    <Box sx={{ flexGrow: 0 }}>
                        {user ?
                            <Tooltip title="Open settings">
                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                    <Avatar alt={user?.displayName || user?.email} src={url} />
                                </IconButton>
                            </Tooltip>
                            :
                            <Typography component="a" href='/login' style={{ textDecoration: "unset", color: "unset" }}>Login</Typography>
                        }
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {settings.map((setting) => (
                                <MenuItem key={setting[0]} onClick={handleCloseUserMenu} component='a' href={setting[1]} sx={{ textDecoration: "unset", color: "inherit" }} >
                                    <Typography textAlign="center">{setting[0]}</Typography>
                                </MenuItem>
                            ))}

                            <MenuItem onClick={() => {
                                handleCloseUserMenu();
                                firebase.auth().signOut();
                            }}
                            >
                                <Typography textAlign="center">Logout</Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}