import React, { useState } from "react";

import {
    FormControlLabel,
    Typography,
    Checkbox,
    Grid,
} from "@mui/material";
import Box from "@mui/material/Box";
import PricingPageImg from "../assets/Images/RresponsiveImages/PricingPageImg";
import OptionsSelector from "../components/TemplateEditorComponents/OptionsSelector"

const audioOptionsList = [
    {
        text: "Open AI TTS ( 10 Cents )",
        value: 0.1,
    },
    {
        text: "ElevenLabs ( 40 Cents )",
        value: 0.4,
    },
];

const imageOptionsList = [
    {
        text: "Stable Defusion ( 30 Cents )",
        value: 0.3,
    },
    {
        text: "Pexels ( 10 Cents )",
        value: 0.1,
    },
];

const videoOptionsList = [
    {
        text: "Normal Video ( 20 Cents )",
        value: 0.2,
    },
    {
        text: "AI Creator ( 3 Dollar )",
        value: 3,
    },
];

const stockOptionsList = [
  {
    text: "AI Cartoon ( 1 Dollar )",
    value: 1.0,
  },{
    text: "Pexels stock videos ( 30 Cents )",
    value: 0.3,
  },
  {
    text: "Private stock videos ( 30 Cents )",
    value: 0.3,
  },
];

const FixedCost = 0.1;

const Pricing = () => {
    //Audio settings
    const [audio, setAudio] = useState(0.4);

    //Other
    const [imageOptions, setImageOptions] = useState(0.1);
    const [videoOptions, setVideoOptions] = useState(3);
    const [stockOptions, setStockOptions] = useState(0.3);
    const [isStock, setIsStock] = useState(false);

    return (
        <div>
            <PricingPageImg />
            <div style={{ backdropFilter: "blur(5px)", padding: "1em", minHeight: "calc(100lvh - 6em)" }}>
                <div className="form-container-1">
                    <h1>Pricing</h1>
                    <br></br>
                    <p>Pricing starts at $0.50 and is dynamic, but you can preview it below.</p>
                </div>
                <div className="form-container-1">
                    <div className="options">
                        <Grid container justifyContent="center">
                        </Grid>
                        <h2>Audio Options</h2>
                        <div className="options-sublevel">
                            <Box
                                component="form"
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "0.5em",
                                }}
                            >
                                <OptionsSelector state={audio} setState={setAudio} optionList={audioOptionsList} label="Audio Options" />
                            </Box>
                        </div>

                        <div className="options-sublevel">
                            <Box
                                component="form"
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "0.5em",
                                }}
                            >
                                <h2 style={{ marginBottom: "0" }}>Other Options</h2>

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isStock}
                                            color="secondary"
                                            onChange={() => setIsStock((prev) => !prev)}
                                            inputProps={{ "aria-label": "controlled" }}
                                        />
                                    }
                                    label={<Typography variant="body1" style={{ fontWeight: 'bold' }}>use stock content</Typography>}
                                />


                                {!isStock ?
                                    <>
                                        <OptionsSelector state={imageOptions} setState={setImageOptions} optionList={imageOptionsList} label="Image Options" />
                                        <OptionsSelector state={videoOptions} setState={setVideoOptions} optionList={videoOptionsList} label="Video Options" />
                                    </>

                                    :
                                    <>
                                        <OptionsSelector state={stockOptions} setState={setStockOptions} optionList={stockOptionsList} label="Video Options" />
                                        <div style={{ height: "3.5em" }}></div>
                                    </>
                                }

                            </Box>
                            <h3>+ Fixed cost(${(FixedCost).toFixed(2)})</h3>

                            <h2>Est. Cost: ${(parseFloat(audio) + (isStock ? parseFloat(stockOptions) : (parseFloat(imageOptions) + parseFloat(videoOptions))) + FixedCost).toFixed(2)}</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default Pricing;
