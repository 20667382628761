import React from "react";

const TryFreeGiftImage = () => {
    return (
        <svg style={{position: "fixed", opacity: "0.3"}} xmlns="http://www.w3.org/2000/svg" width="685.67004" height="428.52452" viewBox="0 0 685.67004 428.52452">
            <path d="M0,427.10999c0,.66003,.53003,1.19,1.19006,1.19H684.48004c.65997,0,1.19-.52997,1.19-1.19,0-.65997-.53003-1.19-1.19-1.19H1.19006c-.66003,0-1.19006,.53003-1.19006,1.19Z" fill="#3f3d56" />
            <path d="M283.49664,428H69.91658c-8.5293,0-15.46826-6.93945-15.46826-15.46875V15.46826c0-8.5293,6.93896-15.46826,15.46826-15.46826h213.58006c8.5293,0,15.46826,6.93896,15.46826,15.46826V412.53125c0,8.5293-6.93896,15.46875-15.46826,15.46875ZM69.91658,2c-7.42627,0-13.46826,6.04199-13.46826,13.46826V412.53125c0,7.42676,6.04199,13.46875,13.46826,13.46875h213.58006c7.42627,0,13.46826-6.04199,13.46826-13.46875V15.46826c0-7.42627-6.04199-13.46826-13.46826-13.46826H69.91658Z" fill="#3f3d56" />
            <g>
                <path d="M666.53394,201.90677c-.8443-1.42365-4.49683,.10068-5.16833-1.19135-.66913-1.28735,2.72449-3.24887,4.66333-7.7298,.34967-.80807,2.55298-5.90033,.92188-7.47162-3.09174-2.97836-17.23962,9.31259-22.19073,5.29041-1.08661-.88269-1.8457-2.64484-5.13428-5.50583-1.30829-1.13815-2.09937-1.65204-2.86499-1.46085-1.08636,.27127-1.17242,1.72522-2.33734,4.58398-1.74701,4.28729-2.78027,4.02841-3.79816,7.44897-.75562,2.53925-.58301,4.01553-1.47217,4.30313-1.25824,.40701-2.41638-2.28632-4.01099-2.04517-1.6272,.24612-2.61096,3.38205-2.92169,5.72998-.58313,4.40607,1.03497,7.31348,1.93182,10.37064,.97461,3.32236,1.55304,8.37494-.69769,15.52759l-22.27716,62.76614c4.91809-12.87943,19.15845-48.18536,25.14215-61.3053,1.72742-3.78745,3.6347-7.62909,7.55096-9.14526,3.77045-1.45978,8.94867-.58823,15.55029-2.17862,.771-.1857,2.91052-.73007,3.17981-1.98849,.22266-1.04037-1.03583-1.62109-.84247-2.59549,.25934-1.30701,2.73413-1.32306,5.77533-2.83096,2.14447-1.06332,3.48047-2.32785,4.62933-3.41531,.34637-.32785,5.49512-5.26166,4.37115-7.1568h-.00006Z" fill="#f2f2f2" />
                <path d="M578.88879,234.94949c-.94232,.0036-1.24805,2.23611-2.07593,2.1929-.82489-.04303-.80847-2.2746-2.4483-4.5191-.29572-.40475-2.1593-2.95547-3.40112-2.60825-2.35382,.65808-.4035,11.14828-3.80615,12.41782-.7467,.27863-1.83069,.14276-4.18372,.93134-.9361,.31372-1.41656,.55371-1.54364,.98465-.1803,.61145,.50842,1.07324,1.57544,2.46979,1.60016,2.09445,1.17499,2.52692,2.56012,4.01343,1.02826,1.10352,1.80261,1.44476,1.68726,1.96414-.16327,.73499-1.81934,.52631-2.16107,1.37851-.34869,.86966,.90662,2.25729,1.9693,3.08725,1.99426,1.5575,3.88812,1.6022,5.6474,2.04413,1.9118,.48026,4.55859,1.65421,7.41974,4.82266l24.37836,29.04382c-4.90228-6.12997-18.12189-23.30609-22.83472-30.0285-1.36047-1.94061-2.69568-3.98517-2.30981-6.34479,.37146-2.27176,2.29327-4.56177,3.41748-8.26077,.13135-.43201,.48145-1.63916-.05847-2.13443-.44635-.40945-1.09448,.04068-1.51697-.33539-.56665-.50443,.13953-1.72369,.27692-3.6514,.09686-1.35931-.13831-2.37988-.34058-3.25754-.06097-.26459-.99695-4.21518-2.25134-4.21036v.00009h-.00018Z" fill="#f2f2f2" />
                <path d="M597.68842,239.16959l-.56775-5.06818,.2594-.38422c1.20038-1.77667,1.81213-3.51175,1.81952-5.15872,.00122-.26184-.01105-.52371-.02325-.79044-.04895-1.05719-.11011-2.37134,.57507-3.90329,.38422-.85406,1.46344-2.83142,3.08594-2.5867,.43683,.06119,.76721,.26184,1.01807,.49677,.03668-.05872,.07465-.11746,.11499-.18109,.50531-.77087,.90546-1.10123,1.29089-1.41937,.29608-.24472,.60199-.49677,1.08167-1.0939,.21045-.26184,.37439-.49188,.5127-.68521,.41974-.58487,.96783-1.28235,1.93451-1.27011,1.03271,.04895,1.57355,.90302,1.93207,1.46832,.63995,1.00824,.92993,1.73508,1.12207,2.21716,.06976,.17621,.14929,.37442,.1897,.43561,.33282,.48944,3.03827,.0318,4.05743-.13461,2.28937-.38177,4.27039-.71213,5.08655,.73172,.5849,1.03271,.15051,2.39825-1.33008,4.1676-.4613,.55063-.95197,.96909-1.38757,1.29703,.36096,.21536,.68396,.54572,.81494,1.06454h0c.30835,1.22849-.74762,2.45944-3.13733,3.66348-.59344,.30099-1.39246,.70236-2.51202,.89569-.52734,.09055-1.00214,.11258-1.40839,.12482-.00861,.23492-.06366,.48944-.20679,.75128-.41974,.77087-1.29333,1.13794-2.60992,1.04984-1.44873-.07587-2.64172-.35239-3.69403-.59467-.91888-.21045-1.71057-.3891-2.33215-.3475-1.15265,.09299-2.04102,1.01559-3.0835,2.21228l-2.6026,3.04187h-.00012Z" fill="#f2f2f2" />
                <path d="M600.32214,208.99365l-4.75842,1.83472-.43744-.15347c-2.02301-.71045-3.85657-.86215-5.45154-.45148-.2536,.06525-.50378,.14349-.75873,.22299-1.01019,.31549-2.26587,.70801-3.92151,.43378-.92358-.155-3.11005-.69739-3.28485-2.32889-.05164-.43805,.05872-.80853,.22229-1.11075-.0661-.0206-.13257-.04239-.20435-.06531-.87384-.2933-1.29486-.59653-1.70044-.88867-.31183-.22437-.63324-.45633-1.33252-.76883-.30664-.13715-.57074-.2374-.79285-.32211-.67218-.2576-1.4859-.61096-1.71924-1.5491-.2146-1.01135,.47437-1.75113,.93024-2.24133,.81299-.87476,1.44244-1.33963,1.86005-1.64774,.15271-.11215,.32428-.23938,.37323-.29395,.38904-.44608-.73987-2.94693-1.15936-3.89062-.94995-2.11765-1.77203-3.95007-.5824-5.10577,.85059-.8277,2.28168-.75391,4.36865,.22946,.64966,.30655,1.17883,.67502,1.60657,1.01321,.11676-.40378,.35437-.80005,.82306-1.05829h0c1.11011-.60986,2.5686,.09933,4.33948,2.10547,.44165,.49768,1.03259,1.16876,1.50354,2.2027,.22131,.48715,.3631,.9408,.47797,1.33066,.22943-.0513,.48956-.06259,.77917,.00946,.85211,.21045,1.42877,.9624,1.67749,2.2583,.29413,1.42062,.32922,2.64474,.36182,3.72409,.02948,.94226,.0575,1.75336,.25543,2.34406,.38232,1.09134,1.50006,1.71661,2.92206,2.42148l3.60254,1.74591-.00012,.00003h.00018Z" fill="#f2f2f2" />
                <path d="M597.68842,194.06265l-.56775-5.06818,.2594-.38422c1.20038-1.77667,1.81213-3.51175,1.81952-5.15872,.00122-.26184-.01105-.52371-.02325-.79044-.04895-1.05719-.11011-2.37134,.57507-3.90329,.38422-.85406,1.46344-2.83142,3.08594-2.5867,.43683,.06119,.76721,.26184,1.01807,.49677,.03668-.05872,.07465-.11746,.11499-.18109,.50531-.77087,.90546-1.10123,1.29089-1.41937,.29608-.24472,.60199-.49677,1.08167-1.0939,.21045-.26184,.37439-.49188,.5127-.68521,.41974-.58487,.96783-1.28235,1.93451-1.27011,1.03271,.04895,1.57355,.90302,1.93207,1.46832,.63995,1.00824,.92993,1.73508,1.12207,2.21716,.06976,.17621,.14929,.37442,.1897,.43561,.33282,.48944,3.03827,.0318,4.05743-.13461,2.28937-.38177,4.27039-.71213,5.08655,.73172,.5849,1.03271,.15051,2.39825-1.33008,4.1676-.4613,.55063-.95197,.96909-1.38757,1.29703,.36096,.21536,.68396,.54572,.81494,1.06454h0c.30835,1.22849-.74762,2.45944-3.13733,3.66348-.59344,.30099-1.39246,.70236-2.51202,.89569-.52734,.09055-1.00214,.11258-1.40839,.12482-.00861,.23492-.06366,.48944-.20679,.75128-.41974,.77087-1.29333,1.13794-2.60992,1.04984-1.44873-.07587-2.64172-.35239-3.69403-.59467-.91888-.21045-1.71057-.3891-2.33215-.3475-1.15265,.09299-2.04102,1.01559-3.0835,2.21228l-2.6026,3.04187h-.00012Z" fill="#f2f2f2" />
                <path d="M602.87408,292.4404l-1.06207-.66565-.25696-1.22604,.25696,1.22604-1.23828,.14194c-.01959-.11502-.08685-.37933-.18842-.79291-.55426-2.26611-2.24408-9.16235-3.65369-20.02304-.98383-7.58145-1.54053-15.37579-1.65558-23.17017-.11499-7.80658,.25452-13.71661,.55066-18.46661,.22394-3.5827,.49554-6.99411,.76233-10.31744,.70972-8.87848,1.37903-17.26505,.88098-26.54974-.11011-2.07278-.34137-6.38721-2.862-10.97818-1.46222-2.66257-3.47626-5.02655-5.98706-7.02347l1.56134-1.96265c2.77142,2.20737,4.99963,4.8259,6.62335,7.78214,2.79474,5.09018,3.04675,9.79129,3.16791,12.05005,.50653,9.44867-.17004,17.91602-.88715,26.88013-.26428,3.31107-.53595,6.70779-.75867,10.27582-.29364,4.70599-.65955,10.56458-.54572,18.27084,.11261,7.70135,.66321,15.40027,1.63348,22.88629,1.39246,10.72366,3.0578,17.51712,3.60474,19.74896,.29126,1.1918,.35242,1.44141,.05383,1.91373v-.00003Z" fill="#f2f2f2" />
                <path d="M583.82501,178.57675c-.10278,0-.20679-.00244-.31201-.0098-2.13397-.11502-4.11011-1.39246-5.87451-3.79562-.82715-1.13062-1.25055-2.42029-2.09485-4.99231-.13092-.39645-.76721-2.40805-1.15387-5.17584-.2533-1.8085-.2215-2.56467,.13702-3.23276,.39764-.74396,1.04126-1.26276,1.76569-1.6127-.0318-.23984-.0061-.487,.0979-.73907,.427-1.04495,1.5625-.9079,2.17682-.84183,.31079,.03915,.69867,.09055,1.11719,.06851,.65826-.0318,1.0119-.2276,1.54785-.52127,.5127-.28143,1.15021-.63138,2.09607-.82471,1.86481-.3891,3.42731,.1395,3.94244,.31323,2.71027,.90302,4.04645,3.01007,5.59308,5.44992,.30835,.48944,1.36676,2.271,2.06543,4.71332,.50415,1.76199,.43317,2.5451,.28143,3.12753-.30835,1.19669-1.03638,1.8819-2.88525,3.39429-1.93085,1.58334-2.8999,2.37625-3.73199,2.85834-1.93695,1.11838-3.15326,1.82071-4.76837,1.82071v.00006h-.00006Z" fill="#f2f2f2" />
            </g>
            <rect x="53.85261" y="176.50562" width="245.70787" height="63.82022" fill="#6c63ff" />
            <rect x="146.39194" y="1" width="63.82022" height="426" fill="#6c63ff" />
            <path d="M212.57114,235.52103c.45195-1.75784,.72131-3.58492,.78685-5.4678l79.82048-72.69366-68.60675-33.50562-30.23698,80.63196c-2.06674-.53998-4.22375-.85667-6.45964-.85667-1.18709,0-2.34602,.10925-3.49007,.26605l-30.01555-80.04133-68.60674,33.50562,76.68331,69.83658c-.04947,.64944-.09904,1.29909-.09904,1.96117,0,2.20132,.30812,4.3259,.83203,6.36371-47.0048,14.69016-66.24776,59.05203-66.24776,59.05203l59.03371,47.86517c-3.52084-35.20874,17.98061-71.25464,29.6046-87.86917,.76054,.06818,1.52715,.11636,2.30553,.11636s1.54488-.0482,2.30553-.11636c11.62399,16.61453,33.12544,52.66046,29.6046,87.86917l59.03371-47.86517s-19.24295-44.36185-66.24776-59.05202l-.00005-.00002Z" isolation="isolate" opacity=".2" />
            <path d="M221.23885,325.19043c-.68848,0-1.38232-.14941-2.0376-.45605-1.65723-.77344-2.7041-2.38574-2.73291-4.20801-.48389-30.86719-18.08447-61.16211-28.5332-76.47266-.69775-1.02246-1.90283-1.63281-3.22412-1.63281h-.00586c-1.36963,0-2.57471,.61035-3.27246,1.63281-10.44824,15.31055-28.04883,45.60547-28.5332,76.47266-.02881,1.82129-1.07568,3.43457-2.73291,4.20801-1.67383,.78418-3.60449,.54688-5.0376-.61426l-49.06738-39.78516c-1.75146-1.4209-2.26562-3.90918-1.22217-5.91797,4.95996-9.55176,24.05518-41.74512,61.53027-54.9834,1.67236-.59082,2.68799-2.25586,2.46924-4.0498-.12158-1.00195-.18311-2.00781-.18311-2.99023,.00244-1.17188-.44531-2.23633-1.22852-2.94922l-70.54199-64.24414c-1.16992-1.06543-1.73438-2.63672-1.51074-4.20312,.22412-1.56641,1.20703-2.91699,2.62891-3.61133l58.73291-28.68262c1.20459-.58887,2.61035-.64111,3.85791-.146,1.24658,.49609,2.23145,1.50146,2.70264,2.75732l27.23242,72.62012c.56201,1.49902,2.00342,2.47754,3.63965,2.43652,1.23389-.02832,2.48535,.04297,3.73291,.20508,1.76074,.23535,3.42236-.75781,4.03857-2.4043l27.32178-72.85742c.47119-1.25586,1.45605-2.26123,2.70264-2.75732,1.24658-.49463,2.65381-.44238,3.85791,.146l58.73192,28.68262c1.42236,.69434,2.40576,2.04492,2.62939,3.6123,.22412,1.56543-.34082,3.13672-1.51074,4.20215l-73.90527,67.30762c-.68896,.62695-1.11768,1.4834-1.20752,2.41016-.01953,.2002-.04053,.40039-.06445,.59961h0c-.20264,1.68555,.84473,3.33105,2.49023,3.91211,37.4834,13.23535,56.58154,45.43457,61.54248,54.9873,1.04346,2.00879,.5293,4.49707-1.22217,5.91797l-49.06787,39.78516c-.87207,.70703-1.9292,1.07031-3,1.07031h.00002Z" fill="#6c63ff" />
            <path d="M635.65582,428.3916h-206.06299c-8.5293,0-15.46875-6.93945-15.46875-15.46875v-104.8457c0-8.5293,6.93945-15.46875,15.46875-15.46875h206.06299c8.5293,0,15.46826,6.93945,15.46826,15.46875v104.8457c0,8.5293-6.93896,15.46875-15.46826,15.46875Zm-206.06299-133.7832c-7.42676,0-13.46875,6.04199-13.46875,13.46875v104.8457c0,7.42676,6.04199,13.46875,13.46875,13.46875h206.06299c7.42627,0,13.46826-6.04199,13.46826-13.46875v-104.8457c0-7.42676-6.04199-13.46875-13.46826-13.46875h-206.06299Z" fill="#3f3d56" />
            <rect x="518.10162" y="292.72849" width="35.20599" height="135.54306" fill="#6c63ff" />
            <rect x="415.12411" y="343.77719" width="235.00003" height="35.20599" fill="#6c63ff" />
            <path d="M525.7522,383.28445c.96973,.2493,1.9776,.39789,3.0163,.43405l40.10095,44.03241,18.48315-37.84644-44.48004-16.68002c.29785-1.14011,.4726-2.32999,.4726-3.56342,0-.65485-.06024-1.29416-.14679-1.92526l44.15424-16.55786-18.48315-37.84644-38.52484,42.30182c-.35828-.02728-.71661-.05463-1.08185-.05463-1.21436,0-2.38635,.16998-3.5105,.45898-8.10376-25.92987-32.57565-36.54514-32.57565-36.54514l-26.40448,32.56555c19.42267-1.94226,39.30713,9.91888,48.47244,16.33118-.0376,.41956-.06421,.84244-.06421,1.27182s.02661,.85223,.06421,1.27182c-9.16528,6.41229-29.04977,18.27341-48.47244,16.33118l26.40448,32.56555s24.47189-10.61526,32.57565-36.54514l-.00006-.00003Z" isolation="isolate" opacity=".2" />
            <path d="M570.31549,421.69629c-.79736,0-1.56396-.33398-2.10938-.93262l-37.13184-40.77246c-.30615-.33594-.75195-.54883-1.25537-.59961l-.16016-.0166c-.90723-.10059-1.73438,.38965-2.01855,1.19531-7.3291,20.7793-25.17236,31.35449-30.46631,34.10059-1.19287,.62109-2.67432,.30859-3.52344-.74121l-21.94922-27.07129c-.6958-.85742-.83643-2.01367-.36768-3.01758,.4624-.98926,1.42383-1.61328,2.50977-1.62988,16.96875-.26172,33.6416-9.94727,42.06982-15.69727,.50684-.34668,.80957-.94434,.80859-1.59863,.00098-.68359-.30127-1.28125-.80859-1.62695-8.42676-5.75-25.09766-15.43555-42.07031-15.69727-1.08594-.0166-2.04736-.6416-2.50928-1.63086-.46875-1.00391-.32812-2.16016,.36768-3.01758l21.94971-27.07129c.8501-1.04688,2.33154-1.35938,3.52246-.74219,5.29346,2.74707,23.13281,13.32129,30.46338,34.09375,.28418,.80469,1.0957,1.30664,1.9126,1.20801,.88916-.1123,1.7749-.1377,2.63086-.07422l36.02588-39.55859c.6377-.7002,1.57861-1.03906,2.51514-.90332,.9375,.13477,1.74512,.72266,2.16016,1.57324l15.82471,32.40234c.35254,.72168,.38379,1.56348,.08691,2.30957-.29688,.74512-.89844,1.33398-1.6499,1.61523l-40.06201,15.02344c-.73682,.27637-1.21826,.99707-1.19824,1.79199l.00342,.29004c0,.62305-.04346,1.25684-.12891,1.88379-.10986,.80664,.39307,1.60938,1.1958,1.91113l40.19043,15.07129c.75146,.28223,1.35303,.87109,1.6499,1.61719,.29639,.74609,.26465,1.58691-.0874,2.30859l-15.82471,32.40234c-.41504,.85059-1.22266,1.43848-2.16016,1.57227-.13525,.01953-.271,.0293-.40576,.0293Z" fill="#6c63ff" />
            <g>
                <path d="M383.68994,171.68843h-32.2265v-10.61121c0-17.78629,14.44019-32.22649,32.22647-32.22649h.00003v42.83768h0v.00002Z" fill="#2f2e41" />
                <polygon points="376.54688 151.87964 382.0878 176.80176 349.61862 171.41035 362.84988 147.84016 376.54688 151.87964" fill="#ffb6b6" />
                <polygon points="376.54688 151.87964 382.0878 176.80176 349.61862 171.41035 362.84988 147.84016 376.54688 151.87964" isolation="isolate" opacity=".1" />
                <rect x="345.41736" y="205.11847" width="31.12952" height="46.07167" fill="#ffb6b6" />
                <path d="M396.78104,288.85684l-6.84848,36.73282s4.35812,25.52618-8.71625,51.67499l-8.71625,26.77139-13.0744-1.86777s8.09366-19.92288,8.09366-28.63916,1.86777-42.33612,1.86777-42.33612l-.9339-38.36282,28.32785-3.9733v-.00003Z" fill="#ffb6b6" />
                <path d="M357.55786,401.54565s14.94217-5.6033,16.18735,2.49036l-.62259,3.73553s5.6033,16.80994-2.49036,18.05511-15.56476,3.11295-16.18735-.62259-.61279-8.39853,.93878-11.67035,2.17416-11.98807,2.17416-11.98807Z" fill="#2f2e41" />
                <path d="M349.65063,283.15878l-3.63742,37.18832s-11.28528,23.30713-5.9989,52.06046l.92703,28.13931,13.07803,1.84204s-2.23364-21.38785,.19046-29.76025c2.42407-8.37241,9.97998-41.18539,9.97998-41.18539l11.5661-36.58966-26.10529-11.69479v-.00003Z" fill="#ffb6b6" />
                <path d="M355.9866,402.3103s-12.79434-9.53781-16.24133-2.10974l-.44086,3.76132s-9.87988,18.59116-2.45181,22.03812,13.90759,3.31613,15.54449-.09888,2.92432-7.89679,2.34387-11.47104,1.24561-12.11981,1.24561-12.11981l.00003,.00003Z" fill="#2f2e41" />
                <path d="M388.68738,229.71077l26.77139,56.65569s-53.85403,27.08267-85.60614,27.08267l15.76575-83.73837h43.06903l-.00003,.00002Z" fill="#2f2e41" />
                <path d="M364.71765,163.40492l15.56476,2.49036-1.24518,39.22318,17.43253,34.86505-22.41324,11.20662s-17.43253-32.99728-20.54547-53.54276-1.24518-35.48764-1.24518-35.48764l12.45181,1.24518-.00003,.00002Z" fill="#e6e6e6" />
                <path d="M352.88846,164.6501l-15.56476,2.49036,1.24518,39.22318-3.73553,36.11023,8.71625,9.96144s17.43253-32.99728,20.54547-53.54276,1.24518-35.48764,1.24518-35.48764l-12.45181,1.24518,.00003,.00002Z" fill="#e6e6e6" />
                <path d="M376.85818,166.82916l3.42426-.93388s12.1405-1.55647,14.00827,3.42426c1.86777,4.98071,1.24518,34.24246,1.24518,34.24246,0,0,3.21255,35.91414-9.28903,36.32347s-10.01126-29.47499-10.01126-29.47499l.62259-43.58131Z" fill="#e6e6e6" />
                <circle cx="371.11374" cy="142.60597" r="16.11325" fill="#ffb6b6" />
                <path d="M366.07315,164.78209c3.45673-.877,7.3757,2.81493,8.75443,8.24841,.58051,2.16293,.63461,4.4334,.15778,6.62146l1.48532,6.17636,.10928-.00575,11.71606,27.61118c2.72412,6.41994,2.43628,13.79018-1.03827,19.83698-2.06693,3.59711-4.78021,6.01532-7.96603,3.54678-5.28632-4.0961-8.7392-27.54909-10.31573-41.02138l-3.64523-13.69443c-1.4632-1.69633-2.49875-3.71846-3.01999-5.89713-1.37897-5.43138,.30573-10.54544,3.76242-11.42249h-.00003Z" fill="#ffb6b6" />
                <path d="M351.02069,166.82916l-3.42426-.93388s-12.1405-1.55647-14.00827,3.42426-1.24518,34.24246-1.24518,34.24246c0,0-3.21255,35.91414,9.28903,36.32347,12.50162,.40935,10.01126-29.47499,10.01126-29.47499l-.62259-43.58131Z" fill="#e6e6e6" />
                <path d="M361.80569,164.78209c-3.45673-.877-7.3757,2.81493-8.75443,8.24841-.58051,2.16293-.63461,4.4334-.15778,6.62146l-1.48532,6.17636-.10928-.00575-11.71606,27.61118c-2.72412,6.41994-2.43628,13.79018,1.03827,19.83698,2.06693,3.59711,4.78021,6.01532,7.96603,3.54678,5.28632-4.0961,8.7392-27.54909,10.31573-41.02138l3.64523-13.69443c1.4632-1.69633,2.49875-3.71846,3.01999-5.89713,1.37897-5.43138-.30573-10.54544-3.76242-11.42249h.00003Z" fill="#ffb6b6" />
                <path d="M340.26523,155.64177c.81961-2.49213,2.43759-4.67393,3.1615-7.19553,.96118-3.34805,3.0076,4.07948,3.20724,.60191,.37949-6.60983,10.46753-23.61252,16.22668-26.87849,5.75912-3.266,9.06686-2.28128,14.93423,.78601,0,0,2.84811,.18113,6.39389,1.93169s6.73743,4.55415,8.11655,8.26021c1.09314,2.93758,.97214,6.16356,1.3374,9.27654,1.01755,8.67264,5.87906,16.92076,4.8356,25.5903-.32553,2.70483-1.35361,5.50188-3.56519,7.09282-2.21158,1.59093-5.78452,1.41786-7.24026-.88496-.49677-.78583-.70682-1.71812-1.13254-2.54462-.42572-.82648-1.19495-1.59695-2.12427-1.57123-1.39685,.03867-2.10785,1.91298-1.55704,3.19724s1.84521,2.05965,3.06055,2.74934c-2.8945,1.30214-6.48508,.89667-9.01627-1.01823-2.53116-1.9149-3.89801-5.25984-3.43222-8.39935,.65637-4.42419,7.10159-3.50848,7.86084-7.91618,.5527-3.20865,2.49374-8.57751,1.96503-11.79019-.52869-3.21269-3.0679,.67728-.39301-1.17902-2.94284,1.41867-9.23755-8.28658-12.31836-9.37363-3.08078-1.08705-5.69293-3.44087-7.09381-6.39223-3.26764,4.7368-6.142,3.45045-7.31226,9.08475-1.17026,5.63428-.08823,18.43474,4.73044,21.58038-1.92844,1.31575-3.46573,3.19733-4.37054,5.34937-.5126,1.21915-.84491,2.55313-1.69574,3.56567-1.62491,1.93387-4.62527,2.0515-7.01675,1.23839-2.96533-1.00822-5.50067-3.22269-6.89859-6.02547s-1.6416-6.16022-.66309-9.1355l-.00003,.00002Z" fill="#2f2e41" />
            </g>
        </svg>
    );
};

export default TryFreeGiftImage;