import React, { useState } from "react";
import {
    Card,
    CardContent,
    Typography,
    Grid,
    CardMedia,
    IconButton,
    Tooltip
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadingIcon from "@mui/icons-material/Downloading";
import InfoIcon from "@mui/icons-material/Info";
import { FloatingDialogBox } from "@two02/frontend-mui";
import processing_gif from "../../assets/Images/processing.gif";
import Faild_gif from "../../assets/Images/Faild.gif";

const TaskCard = ({ video, handleDelete }) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const handleTooltipOpen = () => {
        setTooltipOpen(true);
    };

    const handleTooltipClose = () => {
        setTooltipOpen(false);
    };

    const dateConvert = (date) => {
        try {
            if (!date) return;
            const d = new Date(date);
            const readableDate = d.toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour12: true,
            });
            return <Typography color="text.secondary">Created: {readableDate}</Typography>;
        } catch (e) {
            return;
        }
    };

    const MyTypography = ({ text }) => {
        if (!text) return null;
        const truncateText = (text) => {
            const words = text.split(" ");
            return words.length > 6 ? words.slice(0, 6).join(" ") + "..." : text;
        };
        return (
            <Typography color="text.secondary" style={{ textTransform: "capitalize" }}>
                {truncateText(text)}
            </Typography>
        );
    };

    return (
        <Grid item xs={12} sm={6} md={4} key={video.id} className="drop-shadow-md text-center subpixel-antialiased">
            <Card className="relative">
                <div className="relative">
                    <div>
                        {video.status === "failed" && (
                            <CardMedia
                                component="img"
                                src={Faild_gif}
                                style={{ height: "200px", objectFit: "contain" }}
                            />
                        )}
                        {video.status === "completed" && (
                            <CardMedia
                                component="video"
                                src={video.output}
                                preload="none"
                                autoPlay={false}
                                controls
                                className="ring-2 ring-blue-500"
                                style={{ height: "200px", width: "100%" }}
                            />
                        )}
                        {video.status === "processing" && (
                            <CardMedia
                                component="img"
                                src={processing_gif}
                                alt="Processing"
                                style={{ height: "200px", objectFit: "contain" }}
                            />
                        )}
                    </div>
                </div>
                <CardContent className="flex flex-col items-center justify-between" style={{ padding: '0px' }}>
                    <div className="flex justify-center space-x-2">
                        <Tooltip
                            title={
                                <>
                                    <Typography variant="body2" color="text.secondary">
                                        Video ID: {video.id}
                                    </Typography>
                                    <Typography color="text.secondary">Status: {video.status}</Typography>
                                    {dateConvert(video.jobAt)}
                                </>
                            }
                            open={tooltipOpen}
                            onClose={handleTooltipClose}
                            arrow
                        >
                            <IconButton onClick={handleTooltipOpen}>
                                <InfoIcon />
                            </IconButton>
                        </Tooltip>
                        {video.status === "completed" && (
                            <IconButton
                                color="primary"
                                href={video.output}
                                target="_blank"
                                rel="noopener noreferrer"
                                download={`${video.id}.mp4`}
                            >
                                <DownloadingIcon />
                            </IconButton>
                        )}
                        <IconButton
                            color="secondary"
                            onClick={() => setDialogOpen(true)}
                        >
                            <DeleteIcon />
                        </IconButton>

                        <MyTypography text={video.text} />
                    </div>
                </CardContent>
                <FloatingDialogBox
                    onSubmit={() => handleDelete(video.id)}
                    handleCloseDialog={handleCloseDialog}
                    open={dialogOpen}
                    text={{ header: "Delete Task", paragraph: `Do you want to delete the task:\n${video.id}`, submit: "Yes" }}
                />
            </Card>
        </Grid>
    );
};

export default TaskCard;
