import React, { useCallback, useRef, useState } from "react";
import { HexColorPicker } from "react-colorful";

import { useClickOutside } from "@two02/frontend-general";
import TextField from '@mui/material/TextField';

const ColorPicker = ({ color, onChange, label }) => {
    const popover = useRef();
    const [isOpen, toggle] = useState(false);

    const close = useCallback(() => toggle(false), []);
    useClickOutside(popover, close);

    return (
        <div className="picker">

            <TextField label={label} value={color} onChange={(e) => onChange(e.target.value)} fullWidth
                InputProps={{
                    endAdornment: (
                        <div
                            className="swatch"
                            style={{ backgroundColor: color }}
                            onClick={() => toggle(true)}
                        />
                    ),
                }}

            />

            {isOpen && (
                <div className="popover" ref={popover}>
                    <HexColorPicker color={color} onChange={onChange} />
                </div>
            )}
        </div>
    );
};

export default ColorPicker;