import styles from "../style";
import blackLogo from "../../assets/Images/black version.svg";
import whiteLogo from "../../assets/Images/White version.svg";
import { footerLinks, socialMedia } from "../constants";
import { useTheme } from '@mui/material/styles';

const Footer = () => {
    const theme = useTheme();

    return (
        <section style={{ backdropFilter: "blur(18px" }} className={`${styles.flexCenter} ${styles.paddingY} sm:px-16 px-6 flex-col`}>
            <div className={`${styles.flexStart} md:flex-row flex-col mb-8 w-full`}>
                <div className="flex-[1] flex flex-col justify-start mr-10">
                    <img
                        src={theme.palette.mode !== "dark" ? whiteLogo : blackLogo}
                        alt="hoobank"
                        className="w-[266px] h-[72.14px] object-contain"
                    />
                    <p className={`${styles.paragraph} mt-4 max-w-[312px]`}>
                        Embark on a journey of creativity and whimsy with Vimzy.art
                    </p>
                </div>

                <div className="flex-[1.5] w-full flex flex-row justify-between flex-wrap md:mt-0 mt-10">
                    {footerLinks.map((footerlink) => (
                        <div key={footerlink.title} className={`flex flex-col ss:my-0 my-4 min-w-[150px]`}>
                            <h4 className="font-poppins font-medium text-[18px] leading-[27px]">
                                {footerlink.title}
                            </h4>
                            <ul className="list-none mt-4">
                                {footerlink.links.map((link, index) => (
                                    <li
                                        style={{ opacity: "0.7" }}
                                        key={link.name}
                                        className={`font-poppins font-normal text-[16px] leading-[24px] hover:text-secondary cursor-pointer ${index !== footerlink.links.length - 1 ? "mb-4" : "mb-0"
                                            }`}
                                    >
                                        {link?.newtab ?
                                            <a href={link.link} target="_blank" rel="noreferrer noopener" >
                                                {link.name}
                                            </a>
                                            :
                                            <a href={link.link}>
                                                {link.name}
                                            </a>
                                        }
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>

            <div className="w-full flex justify-between items-center md:flex-row flex-col pt-6 border-t-[1px] border-t-[#3F3E45]">
                <p className="font-poppins font-normal text-center text-[18px] leading-[27px]">
                    Copyright Ⓒ {new Date().getFullYear()} Vimzy.art. All Rights Reserved.
                </p>

                <div className="flex flex-row md:mt-0 mt-6">
                    {socialMedia.map((social, index) => (
                        <img
                            key={social.id}
                            src={social.icon}
                            alt={social.id}
                            className={`${theme.palette.mode !== "dark" && "invert"} w-[40px] h-[40px] object-contain cursor-pointer ${index !== socialMedia.length - 1 ? "mr-6" : "mr-0"
                                }`}
                            onClick={() => window.open(social.link)}
                        />
                    ))}
                </div>
            </div>
        </section>
    )
};

export default Footer;
