import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { NotFound } from "@two02/frontend-general";
import { DarkThemeWrapper } from "@two02/frontend-mui";
import Home from "./pages/Home";
import LoginPage from "./pages/LoginPage";
import Dashboard from "./pages/Dashboard";
import EditTemplate from "./pages/Templates/EditTemplate";
import Tasks from "./pages/Tasks/Tasks";
import NewTask from "./pages/Tasks/NewTask";
import Templates from "./pages/Templates/Templates";
import Pricing from "./pages/Pricing";
import PaymentHistoryPage from "./pages/PaymentHistory";
import TokenHistoryPage from "./pages/TokenHistory";
import TryFree from "./pages/TryFree";
import ToS from "./pages/ToS";
import RedeemPromo from "./pages/RedeemPromo";
import Roadmap from "./pages/Roadmap";
import NavBar from "./components/NavBar";
import Footer from "./tailWindComponents/components/Footer";

import NewPageaTasks from "./newPages/Tasks/Tasks";
import "./Styles/main.scss";
import "./Styles/tailwind.css";

const lightThemePallete = {
  palette: {
    mode: 'light',
    primary: {
      main: "#FFA511",
    }
  },
};

const darkThemePallete = {
  palette: {
    mode: 'dark',
    primary: {
      main: "#33bbcf"
    },

  },
}

function App() {
  return (
    <Router>
      <DarkThemeWrapper NavBar={NavBar} Footer={Footer} lightThemePallete={lightThemePallete} darkThemePallete={darkThemePallete}>
        <Routes>
          <Route path="*" element={<NotFound text={"vimzy"} />} />
          <Route exact path="/" element={<Home />} />
          <Route exact path="/login" element={<LoginPage />} />
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/template/new" element={<EditTemplate />} />
          <Route exact path="/template" element={<Templates />} />
          <Route exact path="/tasks" element={<Tasks />} />
          <Route exact path="/tasks/new" element={<NewTask />} />
          <Route exact path="/pricing" element={<Pricing />} />
          <Route exact path="/history" element={<PaymentHistoryPage />} />
          <Route exact path="/token-usage" element={<TokenHistoryPage />} />
          <Route exact path="/try-free" element={<TryFree />} />
          <Route exact path="/tos" element={<ToS />} />
          <Route exact path="/promo" element={<RedeemPromo />} />
          <Route exact path="/roadmap" element={<Roadmap />} />

          {/* new ui */}
          <Route exact path="/new/task" element={<NewPageaTasks />} />
        </Routes>
      </DarkThemeWrapper>
    </Router>
  );
}

export default App;
