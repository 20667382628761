import React from "react";

export default function ToS() {

    return (
        <div id="main">
            <div className="payment-history component">
                <div className="container">
                    <div className="section">
                        <h1>VIMZY Terms and Conditions</h1>
                    </div>

                    <div className="section">
                        <h2>1. Introduction</h2>
                        <p>Welcome to Vimzy. By accessing our services, you agree to be bound by these Terms and Conditions. Please read them carefully.</p>
                    </div>

                    <div className="section">
                        <h2>2. Payments and Refunds</h2>
                        <p><strong>No Refunds Policy:</strong> All deposits to Vimzy accounts are final. We do not issue refunds after a deposit has been made.</p>
                        <p><strong>Payment Processor Fees:</strong> You are responsible for any fees associated with depositing funds to your account, including but not limited to payment processor fees.</p>
                    </div>

                    <div className="section">
                        <h2>3. Data Usage and Privacy</h2>
                        <p><strong>Data Collection:</strong> We collect data during your use of Vimzy services. We do not sell any data that we collect.</p>
                        <p><strong>Data Usage:</strong> The data collected is used exclusively to improve user experience and service quality.</p>
                    </div>

                    <div className="section">
                        <h2>4. Account Usage</h2>
                        <p><strong>One Account Per User:</strong> Each user is allowed to create and operate a single account. Creating multiple accounts to redeem offers is prohibited.</p>
                        <p><strong>Misuse of Offers:</strong> Any attempt to exploit our offers to spam or otherwise misuse our services will lead to immediate termination of your account without warning.</p>
                    </div>

                    <div className="section">
                        <h2>5. Termination</h2>
                        <p>Vimzy reserves the right to suspend or terminate your access to our services at any time, without notice, for violation of these Terms and Conditions.</p>
                    </div>

                    <div className="section">
                        <h2>6. Amendments</h2>
                        <p>We may amend these Terms and Conditions at any time by posting a revised version on our site. Your continued use of the site and services constitutes your acceptance of any amendments.</p>
                    </div>

                    <div className="section">
                        <p><strong>Acknowledgement:</strong> By using our services, you acknowledge that you have read and agree to be bound by these Terms and Conditions.</p>
                    </div>

                </div>
            </div>
        </div>
    );
};