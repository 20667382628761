import React, { useState, useEffect } from "react";
import { Grid, TextField, Button } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import { useAuthState } from "react-firebase-hooks/auth";
import firebase from "@two02/frontend-firebase/dist/firebaseClient";
import { Notification, Spinner } from "@two02/frontend-mui";
import TaskCard from "./Taskcard";
import NewTask from "./NewTask";
import AuthWrapper from "../../components/AuthWrapper";
import { WebSocketProvider, useWebSocket } from "../../components/WebSocket";
import { fetchTokens } from "@two02/frontend-firebase";
import BASE_URL from "../../assets/config";

const Tasks = () => {
    const [user] = useAuthState(firebase.auth());

    return (
        <AuthWrapper>
            <WebSocketProvider uid={user?.uid}>
                <TaskInner user={user} />
            </WebSocketProvider>
        </AuthWrapper>
    );
};

const TaskInner = ({ user }) => {
    const [videos, setVideos] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [notiMsg, setNotiMsg] = useState("");
    const [notiType, setNotiType] = useState("info");
    const [notiTrigger, setNotiTrigger] = useState(null);
    const [openNewTask, setOpenNewTask] = useState(false);

    const { getProgress } = useWebSocket();

    useEffect(() => {
        if (user) {
            setPage(1); // Reset to page 1 on new user
            setVideos([]); // Clear the previous videos
            setHasMore(true); // Reset hasMore to true
            fetchVideoIds(); // Fetch initial videos
        }
        // eslint-disable-next-line
    }, [user]);


    const fetchVideoIds = async () => {
        const tokens = await fetchTokens(user.uid);
        const response = await fetch(`${process.env.REACT_APP_NEW_BASEURL}/v2/prompt/img2vid/get/${user.uid}?page=${page}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${tokens[0].data.token}`,
            },
        });
        const ids = await response.json();

        if (ids.length === 0) {
            setHasMore(false);
            return;
        }

        for (const idObj of ids) {
            const videoData = await pollProgress(idObj.task_id, idObj);

            const updatedVideo = {
                ...videoData,
                text: idObj.prompt,
                jobAt: idObj.jobAt,
            };

            setVideos((prevVideos) => [...prevVideos, updatedVideo]);
        }

        setPage((prevPage) => prevPage + 1);
    };

    const pollProgress = async (taskId, initialData) => {
        const pollInterval = 2000; // 2 seconds

        return new Promise((resolve, reject) => {
            const checkProgress = async () => {
                try {
                    const videoData = await getProgress(taskId);
                    const parsedData = JSON.parse(videoData);

                    // Update the video details with the current progress
                    const updatedData = {
                        ...initialData,
                        ...parsedData, // This includes status and progress percentage
                    };

                    if (parsedData.status === "COMPLETED") {
                        resolve(updatedData);
                    } else {
                        setVideos((prevVideos) =>
                            prevVideos.map((video) =>
                                video.task_id === taskId ? updatedData : video
                            )
                        );
                        setTimeout(checkProgress, pollInterval);
                    }
                } catch (error) {
                    reject(error);
                }
            };

            checkProgress();
        });
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setPage(1);
        fetchVideoIds();
    };

    const handleDelete = async (id) => {
        try {
            const response = await fetch(`${BASE_URL}/video/delete/${id}`, {
                method: "DELETE",
                headers: { "Content-Type": "application/json" },
            });

            if (response.status === 200) {
                setNotiMsg("Video deleted successfully");
                setNotiType("success");
            } else {
                setNotiMsg("Error deleting video");
                setNotiType("error");
            }
        } catch (error) {
            console.error("Error deleting video:", error);
            setNotiMsg("Error deleting video");
            setNotiType("error");
        } finally {
            setNotiTrigger(Date.now());
            fetchVideoIds();
        }
    };

    useEffect(() => {
        console.log("videos", videos);
    });

    return (
        <>
            <NewTask open={openNewTask} setOpen={setOpenNewTask} user={user} />
            <Grid container justifyContent="center" direction="column" spacing={3}>
                <Grid container justifyContent="center" alignItems="center" direction="column" spacing={3}>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ marginTop: "30px" }}
                            onClick={() => setOpenNewTask(true)}
                        >
                            New Video Task
                        </Button>
                    </Grid>
                </Grid>
                <Grid item sx={{ padding: "0 2em" }}>
                    <TextField
                        fullWidth
                        label="Search by Video ID"
                        variant="outlined"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        style={{ marginBottom: "20px" }}
                    />
                </Grid>
            </Grid>
            <InfiniteScroll
                dataLength={videos.length}
                next={fetchVideoIds}
                hasMore={hasMore}
                loader={<Spinner />}
                endMessage={<p style={{ textAlign: "center" }}>No more videos to display.</p>}
            >
                <Grid container spacing={3}>
                    {videos.map((video) => (
                        <TaskCard handleDelete={handleDelete} video={video} key={video.task_id} />
                    ))}
                </Grid>
            </InfiniteScroll>
            <Notification msg={notiMsg} trigger={notiTrigger} type={notiType} />
        </>
    );
};

export default Tasks;
