import React, { useEffect, useState } from "react";
import {
  FormControl,
  Select,
  MenuItem,
  TextField,
  Button,
  InputLabel,
  Typography,
  Box,
  Stack,
  Switch,
  Modal,
  Backdrop,
  styled
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { Notification } from "@two02/frontend-mui";
import BASE_URL from "../../assets/config";
import { fetchTokens } from "@two02/frontend-firebase";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const InputFileUpload = ({ selectedFile, setSelectedFile }) => {

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      // You can also perform any additional operations with the file here
    }
  };

  return (
    <div>
      <Button
        component="label"
        variant="contained"
        startIcon={<CloudUploadIcon />}
      >
        Upload file
        <VisuallyHiddenInput
          type="file"
          accept="image/png, image/jpeg" // Restrict to PNG and JPEG files
          onChange={handleFileChange}
        />
      </Button>
      {selectedFile && <p>Selected file: {selectedFile.name}</p>}
    </div>
  );
};


const NewTask = ({ open, setOpen, user }) => {
  const [data, setData] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [text, setText] = useState("");
  const [free, setFree] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [notiTrigger, setNotiTrigger] = useState(null);
  const [tokenData, setTokenData] = useState([]);
  const [selectedToken, setSelectedToken] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);

  const getTokens = async () => {
    setTokenData([]);
    const tokens = await fetchTokens(user.uid);
    if (tokens.length === 0) {
      setTokenData([]);
      return;
    }
    const newData = [];
    for (let tokenData of tokens) {
      newData.push(tokenData.data.token);
    }
    setTokenData(newData);
    if (newData.length > 0) {
      setSelectedToken(newData[0]);
    }
  };

  useEffect(() => {
    if (!user) return;
    const fetchData = async () => {
      try {
        if (!user) return;
        const response = await fetch(
          `${BASE_URL}/template/get/uid/${user.uid}`
        );
        const result = await response.json();
        setData(result); // Assuming the result is an array
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    getTokens();
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleChangeText = (event) => {
    setText(event.target.value);
  };

  const handleSubmit = async () => {
    if (!user) return;
    if (!selectedToken) {
      setMessage("Please Select a Token");
      setSeverity("warning");
      setNotiTrigger(Date.now());
      return;
    }
    if (!selectedValue) {
      setMessage("Please Select Template");
      setSeverity("warning");
      setNotiTrigger(Date.now());
      return;
    } else {
      const Obj = {
        templateId: selectedValue,
        is_free: free,
        prompt: text,
      };

      const imgUrl = await handleImageUpload();

      Obj.image = imgUrl;

      fetch(`${process.env.REACT_APP_NEW_BASEURL}/v2/prompt/img2vid/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${selectedToken}`,
        },
        body: JSON.stringify(Obj),
      })
        .then(async (response) => {
          return response.json().then(data => ({
            status: response.status,
            data
          }));
        })
        .then(({ status, data }) => {
          if (status === 200) {
            setMessage(`Task created successfully with id ${data.task_id}`);
            setSeverity("success");
            setNotiTrigger(Date.now());
          }
          else {
            setMessage(`${data.detail}`);
            setSeverity("error");
            setNotiTrigger(Date.now());
            return;
          }
        })
        .catch((error) => {
          setMessage(`Error creating the task`);
          setSeverity("error");
          setNotiTrigger(Date.now());
        });

    }
  }

  const handleImageUpload = async () => {
    const formdata = new FormData();
    formdata.append("file", selectedFile, "upscaling-flux-images-v0-2zgndtor7ukd1-ezgif.com-webp-to-png-converter.png");

    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow"
    };

    return fetch("https://rkco0sk.2-2.site/v2/prompt/upload/", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        console.log(result);
        return result.replace(/^"|"$/g, '');  // Return the result here

      })
      .catch((error) => {
        console.error(error);
        throw error;  // Re-throw the error to ensure it's caught by any caller
      });
  }

  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          sx: {
            backdropFilter: 'blur(10px)',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            borderRadius: '8px',
            bgcolor: 'background.default', // Use MUI default background color
            p: 3, // Optional: Add padding for inner spacing
            maxWidth: 900,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto', // Ensure overflow for any extra content
            height: 'fit-content', // Ensure the height fits the content
            margin: 'auto', // Center horizontally
            position: 'absolute', // Center vertically and horizontally
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)', // Center vertically and horizontally
          }}
        >
          <Grid container direction={"column"} spacing={2}>
            <Box mb={2} sx={{ display: 'flex' }}>
              <Box sx={{ m: "auto" }}>
                <Stack direction="row" spacing={1} alignItems="center" onChange={(e) => setFree(e.target.value)}
                  sx={{
                    color: "primary.main"
                  }}
                >
                  <Typography>Paid</Typography>
                  <Switch defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
                  <Typography>Free</Typography>
                </Stack>
              </Box>
            </Box>

            <Grid item>
              <TextField
                label="Keyword"
                style={{ marginTop: 8 }}
                onChange={handleChangeText}
                value={text}
                fullWidth
              />
              <Typography color="gray">Keyword</Typography>
            </Grid>

            <Grid item>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2, // Adds gap between elements
                }}
              >
                <FormControl variant="outlined" sx={{ minWidth: 200 }}>
                  <InputLabel>Token</InputLabel>
                  <Select
                    label="Token"
                    value={selectedToken}
                    onChange={(e) => setSelectedToken(e.target.value)}
                    required
                  >
                    {tokenData.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl variant="outlined" sx={{ minWidth: 200 }}>
                  <InputLabel>Template</InputLabel>
                  <Select
                    label="Template"
                    value={selectedValue}
                    onChange={handleChange}
                    required
                  >
                    {data.map((item) => (
                      <MenuItem key={item._id} value={item._id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <Box
                  sx={{
                    display: 'flex',
                    gap: 2,
                    ml: 'auto', // Push to the right
                  }}
                >
                  <InputFileUpload selectedFile={selectedFile} setSelectedFile={setSelectedFile} />

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    Generate
                  </Button>
                </Box>

              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal >
      <Notification msg={message} type={severity} trigger={notiTrigger} />
    </>
  )
};

export default NewTask;
