import React from "react";
import { Modal, Box } from "@mui/material";
import { PaypalProvider, PaypalSubscription } from "@two02/frontend-paypal";
import firebase from "@two02/frontend-firebase/dist/firebaseClient";
import { useAuthState } from "react-firebase-hooks/auth";

const Subscription = ({ onClose, openSubscription, fetchFunds, disabled }) => {

    const [user] = useAuthState(firebase.auth());

    const initialOptions = {
        clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
        currency: "USD",
        intent: "capture",
        disableFunding: ["card"],
        vault: true
    };


    return (
        <>
            <Modal
                open={openSubscription}
                onClose={onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="grid justify-center align-center"
            >
                <Box
                    sx={{
                        borderRadius: '8px',
                        bgcolor: 'background.default', // Use MUI default background color
                        color: 'text.primary', // Use MUI text color
                        p: 3, // Optional: Add padding for inner spacing
                        maxWidth: '90vw',
                        minWidth: '50vw',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        overflowY: 'auto', // Ensure overflow for any extra content
                        height: 'fit-content', // Ensure the height fits the content
                    }}
                >
                    <PaypalProvider options={initialOptions}>
                        <PaypalSubscription fetchFunds={fetchFunds} uid={user?.uid ?? ''} planId="11111" disabled={disabled} />
                    </PaypalProvider>
                </Box>
            </Modal>
        </>
    );
}

export default Subscription;