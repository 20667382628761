import React, { useState, useEffect } from "react";
import Modal from '@mui/material/Modal';
import {
    Grid,
    Button,
    Box
} from "@mui/material";
import AudioPlayer from "./AudioPlayer";
import AvatarButton from "./AudioOptionsAvatarButton";


const AudioOptions = ({ setSelectedVoice, selectedVoice, audio, setAudio, setMessage, setSeverity, setNotiTrigger, }) => {
    const [openVoiceMenu, setOpenVoiceMenu] = useState(false);
    const [voices, setVoices] = useState([]);

    const audioOptionsList = [
        {
            text: "Open AI TTS ( 10 Cents )",
            value: 0,
        },
        {
            text: "ElevenLabs ( 40 Cents )",
            value: 1,
        },
    ];

    const [OpenaiVoices,] = useState([
        {
            name: "alloy",
            voice_id: "alloy",
            preview_url: "https://cdn.openai.com/API/docs/audio/alloy.wav",
        },
        {
            name: "echo",
            voice_id: "echo",
            preview_url: "https://cdn.openai.com/API/docs/audio/echo.wav",
        },
        {
            name: "fable",
            voice_id: "fable",
            preview_url: "https://cdn.openai.com/API/docs/audio/fable.wav",
        },
        {
            name: "onyx",
            voice_id: "onyx",
            preview_url: "https://cdn.openai.com/API/docs/audio/onyx.wav",
        },
        {
            name: "nova",
            voice_id: "nova",
            preview_url: "https://cdn.openai.com/API/docs/audio/nova.wav",
        },
        {
            name: "shimmer",
            voice_id: "shimmer",
            preview_url: "https://cdn.openai.com/API/docs/audio/shimmer.wav",
        },
    ]);

    // const handleAudioSelection = (event) => {
    //     setSelectedVoice(null);
    //     setAudio(event.target.value);
    // };

    useEffect(() => {
        const fetchVoices = async () => {
            try {
                const response = await fetch("https://api.vimzy.art/voices/voices/");
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();
                setVoices(data.voices);
            } catch (error) {
                setMessage("Please check your internet connection and try again.");
                setSeverity("error");
                setNotiTrigger(true);
            }
        };

        fetchVoices();
        // eslint-disable-next-line
    }, []);

    return (
        <>

            <Button onClick={() => setOpenVoiceMenu(true)}>Select Audio Options</Button>

            <Modal
                open={openVoiceMenu}
                onClose={() => setOpenVoiceMenu(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="grid justify-center align-center"
            >
                <Box
                    sx={{
                        borderRadius: '8px',
                        bgcolor: 'background.default', // Use MUI default background color
                        p: 3, // Optional: Add padding for inner spacing
                        maxWidth: 600,
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        overflowY: 'auto', // Ensure overflow for any extra content
                        height: 'fit-content', // Ensure the height fits the content
                    }}
                >
                    <Box mb={2} sx={{
                        display: 'flex'
                    }}>
                        {audioOptionsList.map((option) => (
                            <Button
                                key={option.value}
                                onClick={() => { setAudio(option.value); }}
                                sx={{
                                    borderBottom: audio === option.value ? `2px solid` : 'none', // Conditional border
                                    borderBottomColor: 'currentColor', // Use the current text color for the border
                                    '&:hover': {
                                        borderBottom: `2px solid`, // Ensure the border appears on hover as well
                                        borderBottomColor: 'currentColor'
                                    }
                                }}
                            >
                                {option.text}
                            </Button>
                        ))}
                    </Box>

                    <Box
                        sx={{
                            maxHeight: 300,
                            minHeight: 150,
                            overflowY: 'auto',
                            p: 2,
                            border: '1px solid #ccc',
                            borderRadius: 2,
                            mb: 2
                        }}
                    >
                        <Grid container spacing={2}>
                            {(audio === 1 ? voices : OpenaiVoices).map((voice) => (
                                <Grid item xs={12} sm={6} md={4} key={voice.voice_id}>
                                    <Box
                                        onClick={() => setSelectedVoice(voice)}
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            color: 'primary.main',
                                            cursor: 'pointer',
                                            border: selectedVoice === voice ? '2px solid' : '1px solid transparent',
                                            borderColor: selectedVoice === voice ? 'primary.main' : 'transparent',
                                            borderRadius: 2,
                                            p: 2,
                                            '&:hover': {
                                                borderColor: 'primary.light',
                                            },
                                        }}
                                    >
                                        <AvatarButton
                                            // alt={voice.name}
                                            src={voice.avetar_gif}
                                            title={voice.name}
                                            // sx={{ width: 56, height: 56, mb: 1 }}
                                        />
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>

                    <Box mb={2}
                        sx={{
                            color: 'primary.main',
                        }}
                    >
                        <AudioPlayer src={selectedVoice?.preview_url} />
                    </Box>

                    <Box
                        sx={{
                            mt: 'auto', // Push the button to the bottom
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Button onClick={() => setOpenVoiceMenu(false)} variant="contained" color="secondary">
                            Done
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export default AudioOptions