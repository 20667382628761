import React, { useState } from "react";
import { Notification } from "@two02/frontend-mui";
import { Button, Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useAuthState } from "react-firebase-hooks/auth";
import firebase from "@two02/frontend-firebase/dist/firebaseClient";

import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { Modal } from "@mui/material";
import { PaypalProvider } from "@two02/frontend-paypal";


const Prepaid = ({ fetchFunds, disabled, onClose, openPrepaid }) => {
    const [msg, setMsg] = useState("");
    const [notiTrigger, setNotiTrigger] = useState('');
    const [user] = useAuthState(firebase.auth());

    const initialOptions = {
        clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
        currency: "USD",
        intent: "capture",
        disableFunding: ["card"]
    };


    const functions = getFunctions(firebase.app());

    //for local testing of firebase functions{ connectFunctionsEmulator }
    const paypalCreateOrder = httpsCallable(functions, "paypalCreateOrder");
    const paypalHandleOrder = httpsCallable(functions, "paypalHandleOrder");

    async function onApprove(data, _context) {
        paypalHandleOrder({ orderId: data.orderID, uid: user.uid })
            .then(_response => {
                setMsg("THANKS FOR ORDERING!");
                setNotiTrigger(Date.now());
                fetchFunds();
            });
    }

    return (
        <>
            <Modal
                open={openPrepaid}
                onClose={onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="grid justify-center align-center"
            >
                <Box
                    sx={{
                        borderRadius: '8px',
                        bgcolor: 'background.default', // Use MUI default background color
                        color: 'text.primary', // Use MUI text color
                        p: 3, // Optional: Add padding for inner spacing
                        maxWidth: 600,
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        overflowY: 'auto', // Ensure overflow for any extra content
                        height: 'fit-content', // Ensure the height fits the content
                    }}
                >
                    <PaypalProvider options={initialOptions}>
                        <ButtonWrapper onApprove={onApprove} paypalCreateOrder={paypalCreateOrder} disabled={disabled} />
                    </PaypalProvider>

                    <div className='paypal crypto' style={{margin: "0"}}>
                        <CryptoButtonWrapper
                            disabled={disabled}
                            setMsg={setMsg}
                            setNotiTrigger={setNotiTrigger}
                            user={user}
                        />
                    </div>
                </Box>
            </Modal>
            <Notification msg={msg} type={"info"} trigger={notiTrigger} />
        </>
    );
};

export default Prepaid;

const ButtonWrapper = ({ paypalCreateOrder, onApprove, disabled }) => {
    const [amount, setAmount] = useState(10)
    const [{ isPending }] = usePayPalScriptReducer();

    async function createOrder(_data, _context) {
        return paypalCreateOrder({ amount: parseFloat(amount) }).then(response => response.data.id);
    }

    const handleChange = (e) => {
        setAmount(e.target.value);
    };

    return (
        <div className="paypal-button-wrapper">
            {isPending ? <div className="loader" /> : null}
            <span>Add funds from Paypal</span>
            <label className="paypal-choose-amount">
                <div>$&nbsp;</div>
                <input
                    type="number"
                    placeholder="10.00"
                    value={amount}
                    onChange={handleChange}
                    onBlur={handleChange}
                    disabled={disabled}
                />
                <span>
                    + paypal fee
                </span>
            </label>
            <PayPalButtons disabled={disabled} createOrder={createOrder} onApprove={onApprove} forceReRender={[amount]} />
        </div>
    );
};

const CryptoButtonWrapper = ({ disabled, setMsg, setNotiTrigger, user }) => {
    const [crypto_amount, setCryptoAmount] = useState(10);

    const handleChange = (e) => {
        setCryptoAmount(e.target.value);
    }

    const Cryptosubmit = () => {
        try {
            if (!user) {
                setMsg("You need to be logged in to use this feature");
                setNotiTrigger(Date.now());
                return;
            }
            if (
                crypto_amount < 10 ||
                crypto_amount === "" ||
                crypto_amount === 0 ||
                crypto_amount === null ||
                crypto_amount === undefined ||
                !user
            ) {
                setMsg("Minimum amount is 10 USD");
                setNotiTrigger(Date.now());
                return;
            } else {
                setMsg("Redirecting to checkout...");
                setNotiTrigger(Date.now());
                fetch("https://crypto.vimzy.art/create-payment/", {
                    method: "POST", // Specify the HTTP method
                    headers: {
                        "Content-Type": "application/json", // Specify the content type
                    },
                    body: JSON.stringify({
                        uid: user.uid,
                        amount: crypto_amount,
                    }), // Convert the data to JSON format
                })
                    .then((response) => {
                        return response.json().then((data) => ({
                            data: data,
                            status: response.status,
                        }));
                    })
                    .then(({ status, data }) => {
                        if (status === 200) {
                            console.log(data.invoice_data);
                            const url = data.invoice_data;
                            // Open the URL in the current window
                            window.open(url, "_blank");
                        } else {
                            setMsg(data.detail);
                            setNotiTrigger(Date.now());
                        }
                    })
                    .catch((error) => {
                        setMsg("Something went wrong");
                        setNotiTrigger(Date.now());
                    });
            }
        } catch (e) {
            setMsg("Something went wrong");
            setNotiTrigger(Date.now());
        }
    }

    return (
        <div className="paypal-button-wrapper grid">
            <span>Add funds from Crypto</span>
            <label className="paypal-choose-amount">
                <div>$&nbsp;</div>
                <input
                    type="number"
                    value={crypto_amount}
                    onChange={handleChange}
                    disabled={disabled}
                />
                <span>+ Zero fee</span>
            </label>
            <Button
                variant="contained"
                className="buttons"
                onClick={Cryptosubmit}
                disabled={disabled}
            >
                Add Funds via Crypto
            </Button>
        </div>
    );
};

CryptoButtonWrapper.propTypes = {
    disabled: PropTypes.bool.isRequired,
}

ButtonWrapper.propTypes = {
    paypalCreateOrder: PropTypes.func.isRequired,
    onApprove: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
}

Prepaid.propTypes = {
    fetchFunds: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    openPrepaid: PropTypes.bool.isRequired,
}