import React from "react";
import HistoryBgImg from "../assets/Images/RresponsiveImages/HistoryBgImg";
import { TokenHistory } from "@two02/frontend-general";
import { updateTokenHistory } from "@two02/frontend-firebase";

export default function TokenHistoryPage() {
    return (
        <TokenHistory
            BgBlurred
            BgImgComponent={HistoryBgImg}
            updateHistory={updateTokenHistory}
        />
    );
};