import React, { useState } from "react";
import PropTypes from 'prop-types';
import Prepaid from "./Payments/Prepaid";
import { Button } from "@mui/material";
import Subscription from "./Payments/Subscription";

const Payment = ({ fetchFunds, disabled }) => {

    const [display, setDisplay] = useState(0);

    return (
        <div className="flex justify-center">
            <Button
                onClick={() => setDisplay(1)}
            >
                Prepaid
            </Button>
            <Button
                onClick={() => setDisplay(2)}
            >
                Subscription
            </Button>
            <Prepaid fetchFunds={fetchFunds} disabled={disabled} openPrepaid={1 === display} onClose={() => setDisplay(0)} />
            <Subscription fetchFunds={fetchFunds} disabled={disabled} openSubscription={2 === display} onClose={() => setDisplay(0)} />

        </div>
    );
};

export default Payment;

Payment.propTypes = {
    fetchFunds: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
}