const { Button, TextField } = require("@mui/material");
const {default: Notification} = require("@two02/frontend-mui");
const {default: Spinner} = require("@two02/frontend-mui");

module.exports = {
    // Fundamentals
    freeFunds: true,
    tailwind: true,
    mui: true,

    // Utility components
    button: Button,
    notification: Notification,
    floatingDialogBox: null,
    textField: TextField,
    spinner: Spinner
};
