import { airbnb, binance, coinbase, dropbox, youtube, ticktock  } from "../assets";

export const footerLinks = [
  {
    title: "For Developers",
    links: [
      {
        name: "Docs",
        link: "https://docs.vimzy.art/",
        newtab: true,
      },
      {
        name: "Status",
        link: "https://status.vimzy.art/",
        newtab: true,
      },
    ],
  },
  {
    title: "Useful Links",
    links: [
      {
        name: "Dashboard",
        link: "/dashboard",
      },
      {
        name: "Pricing",
        link: "/pricing",
      },
      {
        name: "Redeem your gift",
        link: "/promo",
      },
    ],
  },
  {
    title: "Other",
    links: [
      {
        name: "Terms & Services",
        link: "/tos",
      },
      {
        name: "Roadmap",
        link: "/roadmap",
      },
    ],
  },
];

export const socialMedia = [
  {
    id: "social-media-1",
    icon: ticktock,
    link: "https://tiktok.com/@vimzy.art",
  },
  {
    id: "social-media-2",
    icon: youtube,
    link: "https://youtube.com/@vimzyart?si=svfFVYQ0nzY03V2o",
  },
];

export const clients = [
  {
    id: "client-1",
    logo: airbnb,
  },
  {
    id: "client-2",
    logo: binance,
  },
  {
    id: "client-3",
    logo: coinbase,
  },
  {
    id: "client-4",
    logo: dropbox,
  },
];