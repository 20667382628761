import React, { useEffect, useState } from "react";
import GiftImg from "../assets/Images/RresponsiveImages/GiftImg";
import Sumo from "../assets/Images/appsumo.png"
import { PromoPage } from "@two02/frontend-general";
import { useSearchParams } from "react-router-dom";
import AuthWrapper from "../components/AuthWrapper";

export default function RedeemPromo() {
    const [searchParams,] = useSearchParams();
    const [urlPromoCode, setUrlPromoCode] = useState("");

    useEffect(() => {
        const code = searchParams.get("sumo");

        if (code) {
            setUrlPromoCode(code);
        };

        // eslint-disable-next-line
    }, [searchParams]);

    return (
        <AuthWrapper>
            <PromoPage BgBlurred BgImgComponent={!urlPromoCode && GiftImg} BgImg={urlPromoCode && Sumo} codeFromSumo={urlPromoCode} />
        </AuthWrapper >
    );
};