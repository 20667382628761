import React from "react";
import { Login } from "@two02/frontend-tailwind";
import { useAuthState } from "react-firebase-hooks/auth";
import firebase from "@two02/frontend-firebase/dist/firebaseClient";
import TryFreeGiftImage from "../assets/Images/RresponsiveImages/TryFreeGiftImage";
import { Spinner } from "@two02/frontend-mui";
import {
  handleLoginEmail,
  startLoginEmail,
  handleLoginGithub,
  handleLoginGoogle
} from '@two02/frontend-firebase';

const TryFree = () => {
  const [user, userLoading] = useAuthState(firebase.auth());

  return (
    <>
      <TryFreeGiftImage />
      <div
        style={{
          backdropFilter: "blur(3px)",
          minHeight: "calc(100lvh - 8em)",
        }}
      >
        {userLoading && <Spinner />}
        {!user ? (
          <Login
            handleLoginEmail={handleLoginEmail}
            handleLoginGithub={handleLoginGithub}
            handleLoginGoogle={handleLoginGoogle}
            startLoginEmail={startLoginEmail}

          >
            <div>
              <h1>Welcome!</h1>
              <p>
                Get ready to kickstart your creativity with a delightful
                surprise! Enjoy a warm welcome from us with complimentary
                credits worth $2.50 when you sign in for the first time! It's
                our way of saying 'hello' and giving you a little boost to
                start creating amazing videos! Let's get started on your
                journey to video-making magic together!
              </p>
            </div>
          </Login>
        ) : (
          <div>
            <h1>Hello Again!</h1>
            <p>
              Great news! Your free credits have already been added to your
              account upon signing up. To check your balance, simply visit
              your dashboard and head to the 'Free funds' section. Happy
              creating!
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default TryFree;