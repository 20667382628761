import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import firebase from "@two02/frontend-firebase/dist/firebaseClient";
import { Spinner } from "@two02/frontend-mui";
import { Login } from "@two02/frontend-tailwind";
import {
    handleLoginEmail,
    startLoginEmail,
    handleLoginGithub,
    handleLoginGoogle
} from '@two02/frontend-firebase';

const AuthWrapper = ({ children }) => {
    const [user, userLoading] = useAuthState(firebase.auth());

    return (
        <>
            {user ?
                (children)
                :
                (
                    <Login
                        handleLoginEmail={handleLoginEmail}
                        handleLoginGithub={handleLoginGithub}
                        handleLoginGoogle={handleLoginGoogle}
                        startLoginEmail={startLoginEmail}
                    />
                )
            }

            {userLoading && <Spinner />}
        </>
    )
};

export default AuthWrapper;