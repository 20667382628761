import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import firebase from "@two02/frontend-firebase/dist/firebaseClient";
import { Button } from "@mui/material";
import { Spinner } from "@two02/frontend-mui";
import { Login } from "@two02/frontend-tailwind";
import {
    handleLoginEmail,
    startLoginEmail,
    handleLoginGithub,
    handleLoginGoogle
} from '@two02/frontend-firebase';

const LoginPage = () => {
    const [user, userLoading] = useAuthState(firebase.auth());

    return (
        <>
            {!user ?
                <Login
                    handleLoginEmail={handleLoginEmail}
                    handleLoginGithub={handleLoginGithub}
                    handleLoginGoogle={handleLoginGoogle}
                    startLoginEmail={startLoginEmail}

                />
                :
                <div style={{ textAlign: "center", paddingTop: "5em" }}>
                    <h1>You have already logged In.</h1>
                    <p>Do you want to Logout?</p>
                    <Button onClick={() => firebase.auth().signOut()} color='error' variant="outlined">
                        Logout
                    </Button>
                </div>
            }

            {userLoading && <Spinner />}

        </>
    );
};

export default LoginPage;