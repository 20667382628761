import React, { useEffect, useState } from "react";
import {
  FormControl,
  Select,
  MenuItem,
  TextField,
  Button,
  InputLabel,
  Typography,
  Box,
  Stack,
  Switch,
  Modal,
  Backdrop
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { Notification } from "@two02/frontend-mui";
import BASE_URL from "../../assets/config";
import { fetchTokens } from "@two02/frontend-firebase";

const NewTask = ({ open, setOpen, user }) => {
  const [data, setData] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [mood, setMood] = useState("");
  const [text, setText] = useState("");
  const [textString, setTextString] = useState("");
  const [free, setFree] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [notiTrigger, setNotiTrigger] = useState(null);
  const [tokenData, setTokenData] = useState([]);
  const [selectedToken, setSelectedToken] = useState("");
  const [isKeyWord, setIsKeyWord] = useState(true);

  const getTokens = async () => {
    setTokenData([]);
    const tokens = await fetchTokens(user.uid);
    if (tokens.length === 0) {
      setTokenData([]);
      return;
    }
    const newData = [];
    for (let tokenData of tokens) {
      newData.push(tokenData.data.token);
    }
    setTokenData(newData);
    if (newData.length > 0) {
      setSelectedToken(newData[0]);
    }
  };

  useEffect(() => {
    if (!user) return;
    const fetchData = async () => {
      try {
        if (!user) return;
        const response = await fetch(
          `${BASE_URL}/template/get/uid/${user.uid}`
        );
        const result = await response.json();
        setData(result); // Assuming the result is an array
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    getTokens();
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleChangeMood = (event) => {
    setMood(event.target.value);
  };

  const handleChangeText = (event) => {
    setText(event.target.value);
  };

  const handleChangeTextString = (event) => {
    setTextString(event.target.value);
  };

  const handleSubmit = () => {
    if (!user) return;
    if (!selectedToken) {
      setMessage("Please Select a Token");
      setSeverity("warning");
      setNotiTrigger(Date.now());
      return;
    }
    if (!selectedValue) {
      setMessage("Please Select Template");
      setSeverity("warning");
      setNotiTrigger(Date.now());
      return;
    } else {
      const Obj = {
        templateId: selectedValue,
        mood: mood,
        is_free: free,
      };
      if (isKeyWord) {
        Obj.text = text;
      } else {
        Obj.txt_string = textString;
      }
      if (mood !== "") {
        Obj.mood = mood;
      }

      fetch(`${BASE_URL}/video/new/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Berserker-Token": selectedToken,
        },
        body: JSON.stringify(Obj),
      })
        .then(async (response) => {
          const data = await response.json();
          return ({
            status: response.status,
            data
          });
        })
        .then(({ status, data }) => {
          if (status === 200) {
            setMessage(`Task created successfully with id ${data.id}`);
            setSeverity("success");
            setNotiTrigger(Date.now());

            // Redirect after a delay
            setTimeout(() => {
              window.location.href = "/tasks";
            }, 1000);
          }
          else {
            setMessage(`${data.detail}`);
            setSeverity("error");
            setNotiTrigger(Date.now());
            return;
          }
        })
        .catch((_error) => {
          setMessage(`Error creating the task`);
          setSeverity("error");
          setNotiTrigger(Date.now());
        });

    }
  }

  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          sx: {
            backdropFilter: 'blur(10px)',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            borderRadius: '8px',
            bgcolor: 'background.default', // Use MUI default background color
            p: 3, // Optional: Add padding for inner spacing
            maxWidth: 900,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto', // Ensure overflow for any extra content
            height: 'fit-content', // Ensure the height fits the content
            margin: 'auto', // Center horizontally
            position: 'absolute', // Center vertically and horizontally
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)', // Center vertically and horizontally
          }}
        >
          <Grid container direction={"column"} spacing={2}>
            <Box mb={2} sx={{ display: 'flex' }}>
              <Button
                onClick={() => { setIsKeyWord(true) }}
                sx={{
                  borderBottom: isKeyWord ? `2px solid` : 'none', // Conditional border
                  borderBottomColor: 'currentColor', // Use the current text color for the border
                  '&:hover': {
                    borderBottom: `2px solid`, // Ensure the border appears on hover as well
                    borderBottomColor: 'currentColor'
                  }
                }}
              >
                Just Keyword
              </Button>
              <Button
                onClick={() => { setIsKeyWord(false) }}
                sx={{
                  borderBottom: !isKeyWord ? `2px solid` : 'none', // Conditional border
                  borderBottomColor: 'currentColor', // Use the current text color for the border
                  '&:hover': {
                    borderBottom: `2px solid`, // Ensure the border appears on hover as well
                    borderBottomColor: 'currentColor'
                  }
                }}
              >
                Own Script
              </Button>

              <Box sx={{ m: "auto" }}>
                <Stack direction="row" spacing={1} alignItems="center" onChange={(e) => setFree(e.target.value)}
                  sx={{
                    color: "primary.main"
                  }}
                >
                  <Typography>Paid</Typography>
                  <Switch defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
                  <Typography>Free</Typography>
                </Stack>
              </Box>
            </Box>

            <Grid item>
              {isKeyWord ?
                <>
                  <TextField
                    label="Keyword"
                    style={{ marginTop: 8 }}
                    onChange={handleChangeText}
                    value={text}
                    fullWidth
                  />
                  <Typography color="gray">Keyword</Typography>
                </>
                :
                <>
                  <TextField
                    label="Own Script"
                    style={{ marginTop: 8 }}
                    multiline
                    rows={2}
                    onChange={handleChangeTextString}
                    value={textString}
                    fullWidth
                  />
                  <Typography color="gray">Own Script</Typography>
                </>
              }
            </Grid>

            <Grid item>
              <TextField
                fullWidth
                id="mood"
                label="Context of the video"
                variant="outlined"
                margin="normal"
                onChange={handleChangeMood}
                value={mood}
              />
              <Typography color="gray">Context of the video</Typography>
            </Grid>

            <Grid item>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2, // Adds gap between elements
                }}
              >
                <FormControl variant="outlined" sx={{ minWidth: 200 }}>
                  <InputLabel>Token</InputLabel>
                  <Select
                    label="Token"
                    value={selectedToken}
                    onChange={(e) => setSelectedToken(e.target.value)}
                    required
                  >
                    {tokenData.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl variant="outlined" sx={{ minWidth: 200 }}>
                  <InputLabel>Template</InputLabel>
                  <Select
                    label="Template"
                    value={selectedValue}
                    onChange={handleChange}
                    required
                  >
                    {data.map((item) => (
                      <MenuItem key={item._id} value={item._id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  sx={{ ml: 'auto', mt: 2 }} // Moves button to the rightmost corner and adds top margin for alignment
                >
                  Generate
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal >
      <Notification msg={message} type={severity} trigger={notiTrigger} />
    </>
  )
};

export default NewTask;
