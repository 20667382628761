import React, { useState, useRef, useEffect } from 'react';
import playIcon from "../../assets/Images/play-button-svgrepo-com.svg";
import Pauseicon from "../../assets/Images/pause-button-svgrepo-com.svg";
import '../../Styles/audioPlayer.scss';


const AudioPlayer = ({ src }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [playheadPosition, setPlayheadPosition] = useState(0);
  const audioRef = useRef(null);
  const playheadRef = useRef(null);
  const timelineRef = useRef(null);

  useEffect(() => {
    const audio = audioRef.current;
    const updatePlayhead = () => {
      const timelineWidth = timelineRef.current.offsetWidth - playheadRef.current.offsetWidth;
      const playPercent = timelineWidth * (audio.currentTime / audio.duration);
      setPlayheadPosition(playPercent);
      if (audio.currentTime === audio.duration) {
        setIsPlaying(false);
      }
    };

    audio.addEventListener('timeupdate', updatePlayhead);
    return () => audio.removeEventListener('timeupdate', updatePlayhead);
  }, []);

  useEffect(() => {
    const audio = audioRef.current;
    setIsPlaying(false);
    setPlayheadPosition(0);
    audio.pause();
    audio.load();
  }, [src]);

  const playPause = () => {
    const audio = audioRef.current;
    if (audio.paused) {
      audio.play();
      setIsPlaying(true);
    } else {
      audio.pause();
      setIsPlaying(false);
    }
  };

  const movePlayhead = (event) => {
    const timelineWidth = timelineRef.current.offsetWidth - playheadRef.current.offsetWidth;
    const newMargLeft = event.clientX - timelineRef.current.getBoundingClientRect().left;
    const newPosition = Math.max(0, Math.min(newMargLeft, timelineWidth));
    setPlayheadPosition(newPosition);
    audioRef.current.currentTime = (newPosition / timelineWidth) * audioRef.current.duration;
  };

  const handleTimelineClick = (event) => {
    movePlayhead(event);
  };

  const handleMouseDown = () => {
    window.addEventListener('mousemove', movePlayhead);
    window.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseUp = () => {
    window.removeEventListener('mousemove', movePlayhead);
    window.removeEventListener('mouseup', handleMouseUp);
  };

  return (
    <div id="audioplayer">
      <audio id="music" ref={audioRef} preload="true">
        <source src={src} />
      </audio>
      <button
        id="pButton"
        onClick={playPause}
      >
        <img src={isPlaying ? playIcon : Pauseicon} alt='play/pause'/>
      </button>
      <div
        id="timeline"
        ref={timelineRef}
        onClick={handleTimelineClick}
      >
        <div
          id="playhead"
          ref={playheadRef}
          style={{ marginLeft: `${playheadPosition}px` }}
          onMouseDown={handleMouseDown}
        ></div>
      </div>
    </div>
  );
};

export default AudioPlayer;