import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import PropTypes from "prop-types";


const OptionsSelector = ({ state, setState, optionList, label }) => {

    const handleChange = (event) => {
        setState(event.target.value);
    };

    return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">{label}</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    value={state}
                    label={label}
                    onChange={handleChange}
                >

                    {optionList.map((option, index) => (
                        <MenuItem key={option.value} value={option.value}>{option.text}</MenuItem>
                    ))}

                </Select>
            </FormControl>
        </Box>
    );
};

export default OptionsSelector;

OptionsSelector.propTypes = {
    state: PropTypes.any,
    setState: PropTypes.func.isRequired,
    optionList: PropTypes.array.isRequired, 
    label: PropTypes.string.isRequired
}