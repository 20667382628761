import React, { useEffect, useRef } from "react";
import { Canvas, useThree } from "@react-three/fiber";
import PropTypes from "prop-types";

const videoAspectRatio = 2;
const videoHeight = 5;
const videoWidth = videoAspectRatio * videoHeight;

const Display = ({ logoPosition, textPosition, highlightedColor }) => {
    const containerRef = useRef(null);

    return (
        <Canvas orthographic ref={containerRef}>
            <mesh>
                <planeGeometry args={[videoWidth, videoHeight]} />
                <meshBasicMaterial />
            </mesh>

            <Logo logoPosition={logoPosition} />
            <Text textPosition={textPosition} highlightedColor={highlightedColor} />

            <CameraZoom containerRef={containerRef} />

        </Canvas>
    );
};

const CameraZoom = ({containerRef}) => {
    const { camera } = useThree();

    useEffect(() => {
        if (videoAspectRatio < 1 || containerRef?.current?.clientWidth / containerRef?.current?.clientHeight > videoAspectRatio) {
            camera.zoom = 2 * camera.top / videoHeight;
        } else {
            camera.zoom = 2 * camera.right / videoWidth;
        }

        camera.updateProjectionMatrix();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [containerRef?.current?.clientHeight, containerRef?.current?.clientWidth]);
};

const Logo = ({ logoPosition }) => {
    const logoRef = useRef(null);

    useEffect(() => {
        if (!logoRef.current) return;

        switch (logoPosition) {
            case "top-left":
                logoRef.current.position.x = 0.5 - (videoWidth * 0.5);
                logoRef.current.position.y = videoHeight * 0.5 - 0.5;
                break;

            case "top-right":
                logoRef.current.position.x = videoWidth * 0.5 - 0.5;
                logoRef.current.position.y = videoHeight * 0.5 - 0.5;
                break;

            case "bottom-left":
                logoRef.current.position.x = 0.5 - (videoWidth * 0.5);
                logoRef.current.position.y = -(videoHeight * 0.5 - 0.5);
                break;

            case "bottom-right":
                logoRef.current.position.x = videoWidth * 0.5 - 0.5;
                logoRef.current.position.y = -(videoHeight * 0.5 - 0.5);
                break;

            default:
                logoRef.current.position.x = 0.5 - (videoWidth * 0.5);
                logoRef.current.position.y = videoHeight * 0.5 - 0.5;
        }

    }, [logoPosition]);
    return (
        <mesh ref={logoRef}>
            <planeGeometry />
            <meshBasicMaterial color={"black"} />
        </mesh>
    );
};

const Text = ({ textPosition, highlightedColor }) => {
    const textRef = useRef(null);

    useEffect(() => {
        if (!textRef.current) return;

        switch (textPosition) {

            case 'top':
                textRef.current.position.y = videoHeight * 0.5 - 0.5;
                break;

            case 'middle':
                textRef.current.position.y = 0;
                break;

            case 'bottom':
                textRef.current.position.y = 0.5 - videoHeight * 0.5;
                break;

            default:
                textRef.current.position.y = videoHeight * 0.5 - 0.5;
        }

    }, [textPosition]);
    return (
        <mesh ref={textRef}>
            <planeGeometry args={[3, 1]} />
            <meshBasicMaterial color={highlightedColor} />
        </mesh>
    );
};

export default Display;

Display.propTypes = {
    logoPosition: PropTypes.string,
    textPosition: PropTypes.string,
    highlightedColor: PropTypes.string
}

Logo.propTypes = {
    logoPosition: PropTypes.string
}

Text.propTypes = {
    textPosition: PropTypes.string,
    highlightedColor: PropTypes.string
}