import React, { useEffect, useRef, createContext, useContext } from 'react';
import { fetchTokens } from '@two02/frontend-firebase';

const WebSocketContext = createContext(null);

function WebSocketProvider({ uid, children }) {
    const socketRef = useRef(null);  // Use ref to keep track of WebSocket instance
    const isConnectedRef = useRef(false); // Use ref to keep track of connection status

    const initWebsocket = async (callbackFunct) => {
        if (socketRef.current) return; // Prevent reinitialization if socket already exists

        try {
            const tokens = await fetchTokens(uid);

            // Create the WebSocket connection
            const ws = new WebSocket('https://rkco0sk.2-2.site/v2/tasks/socket');

            // Set up event listeners
            ws.onopen = () => {
                console.log('Connected to WebSocket');
                isConnectedRef.current = true;

                ws.send(tokens[0].data.token);
                ws.send("ping");

                if (callbackFunct) {
                    callbackFunct(ws); // Pass the WebSocket instance directly
                }
            };

            ws.onmessage = (event) => {
                console.log('Message from server: ', event.data);
            };

            ws.onerror = (error) => {
                console.error('WebSocket error: ', error);
                isConnectedRef.current = false;
            };

            ws.onclose = () => {
                console.log('WebSocket connection closed');
                isConnectedRef.current = false;
                socketRef.current = null; // Reset socket ref on close
            };

            socketRef.current = ws; // Store WebSocket instance in ref
        } catch (error) {
            console.error('Failed to initialize WebSocket:', error);
            isConnectedRef.current = false;
        }
    };

    useEffect(() => {
        initWebsocket();

        return () => {
            if (socketRef.current) socketRef.current.close();
        };
        // eslint-disable-next-line
    }, [uid]);

    const ping = () => {
        if (isConnectedRef.current && socketRef.current) {
            socketRef.current.send("ping");
        } else {
            console.warn("Cannot send ping: WebSocket is not connected");
        }
    };

    const getProgress = async (id) => {
        return new Promise(async (resolve, reject) => {
            const sendProgressRequest = () => {
                socketRef.current.send("get_progress");
                socketRef.current.send(id);

                const handleMessage = (event) => {
                    resolve(event.data);
                    socketRef.current.removeEventListener('message', handleMessage);
                };

                socketRef.current.addEventListener('message', handleMessage);

                socketRef.current.onerror = (error) => {
                    reject(error);
                };
            };

            if (!socketRef.current || !isConnectedRef.current) {
                console.log("Socket not ready, initializing WebSocket...");
                await initWebsocket(() => sendProgressRequest());
            } else {
                sendProgressRequest(socketRef.current);
            }
        });
    };

    return (
        <WebSocketContext.Provider value={{ ping, getProgress }}>
            {children}
        </WebSocketContext.Provider>
    );
}

const useWebSocket = () => {
    const context = useContext(WebSocketContext);
    if (!context) {
        throw new Error('useWebSocket must be used within a WebSocketProvider');
    }
    return context;
};

export { WebSocketProvider, useWebSocket };
