import React from "react";
import HomeAnimation from "../components/HomeAnimation/HomeAnimation";
// import FAQ from "../components/HomeAnimation/FAQ";

const Home = () => {
    return (
        <div id="home">
            <div
                className="relative"
                style={{ height: "calc(300vh - 4em)" }}
            >
                <HomeAnimation fixed={document.documentElement.scrollHeight > window.innerHeight} />
            </div>
        </div>
    );
};

export default Home;
